import { ACCESS_TOKEN_NAME, DATE_FORMAT, ROLE_TYPES, USER_ROLE } from "api/constants";
import dayjs from 'dayjs';

export const getRole = () => {
  return localStorage.getItem(USER_ROLE);
};

export const getAuthToken = (domain) => {
  const tokenMap = {
    [ROLE_TYPES.admin]: localStorage.getItem(ACCESS_TOKEN_NAME.admin),
    [ROLE_TYPES.slh_manager]: localStorage.getItem(ACCESS_TOKEN_NAME.slh_manager),
    [ROLE_TYPES.client]: localStorage.getItem(ACCESS_TOKEN_NAME.client),
  };

  return tokenMap[domain];
};

export const isInRoute = (path, route) => {
  return path.includes(route);
}

export const buildURL = (baseURL, endpoint, params) => {
  const url = new URL(endpoint, baseURL);

  Object.keys(params).forEach(key => {
    if (params[key] !== undefined && params[key] !== null) {
      url.searchParams.append(key, params[key]);
    }
  });

  return url.toString();
};

export const getBaseUrl = (url) => {
  const urlPattern = /^(https?:\/\/[^\s/$.?#].[^\s]*)$/;
  if (urlPattern.test(url)) {
    const urlObj = new URL(url);
    return `${urlObj.origin}${urlObj.pathname}`.slice(0, -1);
  }
  return null;
}

export const splitName = (name) => {
  const [firstName, ...lastNameParts] = name.split(' ');
  const lastName = lastNameParts.join(' ');
  return { firstName, lastName };
};

export const formatPriceWithComma = (price) => {
  return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const formatDate = (date) => {
  return dayjs(date).format(DATE_FORMAT)
}

export const calculateWeeksBetweenDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const differenceInMilliseconds = end - start;

  const millisecondsPerWeek = 7 * 24 * 60 * 60 * 1000;
  const weeks = differenceInMilliseconds / millisecondsPerWeek;

  return Math.floor(weeks);
};

export const getIdFromPath = (path) => {
  const segments = path.split('/');
  const userId = segments.pop();


  return parseInt(userId, 10);
};

export const getDynamicParamsFromPath = (path) => {
  const segments = path.split('/');
  const params = segments.filter(i => Number.isInteger(Number(i))).slice(1, segments.length - 1);

  return params
};

export function splitPeriodIntoWeeks(startDateStr, endDateStr) {
  const today = new Date();
  const week = new Array(7).fill(false);
  const weeks = new Array(7).fill(week);
  const start = new Date(startDateStr);
  const end = new Date(endDateStr);
  const endWithCurrentCalc = end > today ? today : end;

  const startDay = start.getUTCDay();
  const startMonday = new Date(start);
  startMonday.setUTCDate(start.getUTCDate() - (startDay === 0 ? 6 : startDay - 1));

  const endDay = endWithCurrentCalc.getUTCDay();
  const endSunday = new Date(endWithCurrentCalc);
  endSunday.setUTCDate(endWithCurrentCalc.getUTCDate() + (6 - endDay));

  let currentWeekStart = new Date(startMonday);
  let weekIndex = 0;

  while (currentWeekStart <= endSunday) {
    const weekArray = new Array(7);
    const weekEnd = new Date(currentWeekStart);
    weekEnd.setUTCDate(weekEnd.getUTCDate() + 6);

    for (let i = 0; i < 7; i++) {
      const day = new Date(currentWeekStart);
      day.setUTCDate(day.getUTCDate() + i);

      weekArray[i] = (day >= start && day < endWithCurrentCalc)
    }

    weeks[weekIndex] = weekArray;
    if (weekIndex < 6) weekIndex++;
    currentWeekStart.setUTCDate(currentWeekStart.getUTCDate() + 7);
  }

  return weeks;
}

export const getActiveWeekAndDaysSinceStart = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  const today = dayjs();

  let activeWeek, daysSinceStart;

  if (today.isBefore(start)) {
    activeWeek = -1;
    daysSinceStart = 0;
  } else if (today.isAfter(end)) {
    activeWeek = 7;
    daysSinceStart = 42;
  } else {
    daysSinceStart = today.diff(start, 'days');
    activeWeek = Math.ceil(daysSinceStart / 7);
  }

  return { activeWeek, daysSinceStart };
};
