import { Outlet } from 'react-router-dom';
import { ClientHeader } from 'pages';

const RootLayoutClient = () => {
  return (
    <div className="flex flex-col min-h-screen bg-bgColor gap-y-6 px-10 py-6">
      <ClientHeader/>
      <div className="flex w-full">
        <Outlet/>
      </div>
    </div>
  );
}

export default RootLayoutClient;
