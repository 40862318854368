import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { formatPriceWithComma } from 'utils/helpers';
import { CLIENT_STATUS, ROLE_TYPES } from 'api/constants';
import { IconEye } from 'components/icons/Eye';
import { COLORS } from 'utils/colors';
import { Terminate as TerminateModal } from 'components';

export const SingleWeekInRow = ({ weekIndex, week, client, domain }) => {
  const [isTerminateOpen, setIsTerminateOpen] = useState(false);
  const weekStatus = useMemo(() => {
    const element = week.days.slice().reverse().find(day => day.day_status !== null);

    return element ? element.day_status : CLIENT_STATUS.not_started;
  }, [week.days])

  const statusAbbr = Array.from(weekStatus)[0].toUpperCase();

  const isRelapsed = useMemo(() => weekStatus === CLIENT_STATUS.failed, [weekStatus]);
  const userData = useMemo(() => {
    return {
      client: {
        termination_date: client.termination_date,
        termination_reason: client.termination_reason,
        termination_details: client.termination_details,
        name: client.partner
      },
      profile: {
        user_id: client.user_id,
        avatar: client.avatar,
        firstname: client.firstname,
        lastname: client.lastname,
      }
    }
  }, [client.avatar, client.firstname, client.lastname, client.partner, client.termination_date, client.termination_details, client.termination_reason, client.user_id]);

  const fillDayBullet = useCallback((status) => {
    switch (status) {
      case CLIENT_STATUS.completed:
        return 'bg-green';
      case CLIENT_STATUS.in_progress:
        return 'bg-white border border-green';
      case CLIENT_STATUS.failed:
        return 'bg-red translate-y-1/2';
      default:
        return 'bg-grey';
    }
  }, [])

  return (
    <div className={classNames(
      'flex flex-col items-center justify-flex-start py-2 flex-1 relative',
      {
        'border-r border-color-divider after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-color-divider after:bg-white after:z-10 after:top-1/2 after:left-full after:-translate-y-1/2 after:-translate-x-1/2': weekIndex < 6,
        'bg-divider': weekStatus === CLIENT_STATUS.in_progress || isRelapsed,
        'bg-lightGreen': weekStatus === CLIENT_STATUS.completed,
        'bg-bgColor': weekStatus === CLIENT_STATUS.not_started,
      })}
    >
      <div className='flex items-center gap-x-1'>
        {weekStatus !== CLIENT_STATUS.not_started && <p className='w-6 h-6 rounded-full bg-white shadow-block-shadow flex justify-center items-center'>
          <span className={classNames('uppercase text-xl leading-5 font-medium',
            { 'text-blue': statusAbbr === 'I' },
            { 'text-green': statusAbbr === 'C' },
            { 'text-red': statusAbbr === 'R' },
          )}>
            {statusAbbr}
          </span>
        </p>}
        <p className={classNames('text-14', {
          'text-darkGrey': weekStatus === CLIENT_STATUS.not_started,
          'text-green': weekStatus === CLIENT_STATUS.completed,
          'text-blue': weekStatus === CLIENT_STATUS.in_progress,
          'text-red': isRelapsed
        })}>Week {weekIndex + 1}</p>
        {isRelapsed && <IconEye color={COLORS.blue} className='cursor-pointer ml-1' onClick={() => setIsTerminateOpen(true)} />}
        {isRelapsed && isTerminateOpen && <TerminateModal
          isAdmin={domain === ROLE_TYPES.admin}
          isOpen={isTerminateOpen}
          userDetails={userData}
          onClose={() => setIsTerminateOpen(false)}
        />}
      </div>
      <div className={`flex items-center gap-x-[1px] mb-2 ${weekStatus === CLIENT_STATUS.not_started ? 'mt-4' : 'mt-3'}`}>
        {week.days.map((day, index) => {
          return <span key={index} className={classNames(`${fillDayBullet(day.day_status)} rounded-[10px] w-2 h-2.5`)} />
        })}
      </div>
      {weekStatus !== CLIENT_STATUS.not_started && <div className='flex items-center font-medium text-14 gap-x-1'>
        <span className='text-lightBlue'>Granted:</span>
        <span className='font-medium'>${formatPriceWithComma(week.granted)}</span>
      </div>}
    </div>
  );
};
