import React, { useEffect, useState } from 'react';
import { Form, DatePicker, Radio, Checkbox, Input } from 'antd';
import { CustomUploadPhoto, FormInput, IconSmallArrow, OrangeButton, IconCalendar, FileUpload, SelectOption, TooltipButton, IconInfo, IconSearch, IconClipboardType } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import dayjs from 'dayjs';
import { getRequestData, updateClient, updateUserImage, uploadDocument } from 'api/api';
import { endpoints } from 'api/endpoints';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATE_FORMAT, ROLE_TYPES } from 'api/constants';
import { getIdFromPath } from 'utils/helpers';

const defaultFormData = {
  profile: {
    avatar: "",
    firstname: "",
    lastname: "",
    email: "",
    gender: "",
    phone: "",
    birthdate: "",
    identifyas: "",
    documents: [],
    startDate: '',
    slh: '',
    tasks: []
  },
};

export const EditClient = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [avatarImg, setAvatarImg] = useState(null);
  const [selectedDoc, setSelectedDoc] = useState(null);
  const [form] = Form.useForm();

  const [formData, setFormData] = useState(defaultFormData);

  useEffect(() => {
    const userID = getIdFromPath(location.pathname);
    (async () => {
      const { data } = await getRequestData(endpoints.getUser(userID), ROLE_TYPES.admin);

      if (data) {
        const updatedFormData = {
          profile: {
            ...data.profile,
            avatar: data.profile.avatar,
            firstname: data.profile.firstname,
            lastname: data.profile.lastname,
            email: data.profile.email,
            gender: data.profile.gender,
            phone: data.profile.phone,
            identifyas: data.profile.identifyas,
            birthdate: data.profile.birthdate,
            documents: data.client.documents,
            startDate: data.client.start_date,
            slh: data.client.name,
            tasks: data.client.tasks
          },
        }
        setFormData(updatedFormData);
        form.setFieldsValue(updatedFormData);
      }
    })();
  }, [form, location.pathname])

  const handleImageUpload = (file) => {
    setAvatarImg(file);
  };

  const handleGenderChange = (e) => {
    const shortGender = e.target.value.slice(0, 1).toUpperCase();

    setFormData({
      ...formData,
      profile: {
        ...formData.profile,
        gender: shortGender,
      }
    });
  }

  const handleDateOfBornChange = (date) => {
    const birthdate = dayjs(date).format('YYYY-MM-DDTHH:mm:ssZ');

    setFormData({
      ...formData,
      profile: {
        ...formData.profile,
        birthdate
      }
    });
  }

  const handleDocSelect = (file) => {
    setSelectedDoc(file);
  };

  const handleFormSubmit = (values) => {
    const userID = formData.profile.user_id;

    values = {
      ...values,
      profile: {
        ...values.profile,
        gender: formData.profile.gender,
        birthdate: formData.profile.birthdate,
      }
    };

    updateClient(values, userID, ROLE_TYPES.admin, () => {
      avatarImg && updateUserImage(userID, avatarImg, ROLE_TYPES.admin);
      selectedDoc && uploadDocument(userID, selectedDoc, ROLE_TYPES.admin);
      navigate('/admin/clients');
    })
  }

  return formData.profile.firstname && (
    <Form
      form={form}
      name='edit-client-by-admin'
      className='flex flex-col w-full gap-6'
      initialValues={formData}
      onFinish={handleFormSubmit}
      requiredMark={false}
    >
      <div className='flex justify-between w-full'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate('/admin/clients')} className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
          <p className='nunito-title'>Edit Client</p>
        </div>
        <OrangeButton type='submit' label='Save' />
      </div>
      <div className='flex gap-6'>
        <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[442px]'>
          <p className='nunito-title px-[30px] pb-[11px]'>Client Information</p>
          <div className={classNames('relative w-full flex flex-col pt-14 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <CustomUploadPhoto
              avatar={formData.profile.avatar}
              className='self-center'
              onImageUpload={handleImageUpload}
            />
            <div className='flex gap-x-[18px]'>
              <FormInput
                label="First Name"
                name={['profile', 'firstname']}
                placeholder="First Name"
              />
              <FormInput
                label="Last Name"
                name={['profile', 'lastname']}
                placeholder="Last Name"
              />
            </div>
            <Form.Item className='flex flex-col w-full'>
              <p className='text-14 font-medium mb-2'>Date of Birth</p>
              <DatePicker
                onChange={(_, date) => handleDateOfBornChange(date)}
                format={DATE_FORMAT}
                placeholder='Pick Date'
                className='h-[44px] text-base font-medium base-block rounded-lg w-full'
                defaultValue={dayjs(formData.profile.birthdate)}
                suffixIcon={<IconCalendar color={COLORS.blue} />}
              />
            </Form.Item>
            <FormInput
              className='w-full'
              label="Email"
              value={formData.profile.email}
              disabled
            />
            <div className='flex gap-x-[18px]'>
              <FormInput
                type='password'
                label="Password"
                placeholder="Password"
                value='123456789'
                visibilityToggle={false}
                disabled
              />
              <FormInput
                type='password'
                label="Confirm Password"
                placeholder="Password"
                value='123456789'
                visibilityToggle={false}
                disabled
              />
            </div>
            <FormInput
              className='w-full'
              inputClassName='[&>*]:cursor-default'
              label="Phone"
              name={['profile', 'phone']}
              placeholder="Phone"
              prefix='+1'
            />
            <div className='flex flex-col gap-y-1 w-full'>
              <p className='text-14 font-medium'>Document</p>
              <FileUpload className='w-full' onFileSelect={handleDocSelect} maxCount={1} files={formData.profile?.documents} />
            </div>
            <div className='flex flex-col w-full gap-y-1'>
              <p className='text-14 font-medium'>SLH</p>
              <SelectOption
                dropdownStyle={{ padding: '16px', background: 'white' }}
                disabled
                suffixIcon={<IconSmallArrow color={COLORS.darkGrey} className='rotate-[-90deg] w-3 h-3' />}
                className='h-[44px] text-base font-medium base-block rounded-lg placeholder:!text-darkGrey'
                placeholder={formData.profile.slh}
              />
              <div className='flex justify-between mt-1'>
                <Checkbox className='flex items-center !bg-transparent' checked disabled><p className='text-16 ml-2.5 text-darkGrey'>Accepted at SLH*</p></Checkbox>
                <TooltipButton
                  icon={<IconInfo color={COLORS.blue} />}
                  text='Info'
                  tooltipText='Clients must have already applied for entry to a partner SLH and been accepted for a term of residency at the SLH.' />
              </div>
            </div>
            <Form.Item className='flex flex-col w-full'>
              <p className='text-14 font-medium mb-2'>Date of Start</p>
              <DatePicker
                format={DATE_FORMAT}
                placeholder='Pick Date'
                className='h-[44px] text-base font-medium base-block rounded-lg w-full'
                defaultValue={dayjs(formData.profile.startDate)}
                suffixIcon={<IconCalendar color={COLORS.darkGrey} />}
                disabled
              />
            </Form.Item>
            <Form.Item className='flex flex-col w-full'>
              <p className='text-14 font-medium mb-2'>Gender</p>
              <Radio.Group onChange={handleGenderChange} name={['profile', 'gender']} className='gap-y-4' defaultValue={formData.profile.gender}>
                <Radio className='px-4 py-2.5 bg-white border border-color-divider rounded-xl' value={'M'}>Male</Radio>
                <Radio className='px-4 py-2.5 bg-white border border-color-divider rounded-xl' value={'F'}>Female</Radio>
              </Radio.Group>
            </Form.Item>
            <FormInput
              className='w-full'
              label="Identify as"
              name={['profile', 'identifyas']}
              placeholder="Identify as"
            />
          </div>
        </div>
        <div className='flex flex-col base-block pt-[30px] flex-1'>
          <div className='flex justify-between px-[30px] pb-[17px]'>
            <p className='nunito-title'>FORMS</p>
            <Input
              className='search w-[300px] text-lightBlue text-16 rounded-ten bg-light'
              type='text'
              placeholder="Search"
              addonBefore={<IconSearch color={COLORS.lightBlue} />}
            // onSearch={onSearch}
            />
          </div>
          {formData.profile.tasks.length > 0 && <div className={classNames('relative w-full flex flex-col pt-6 pb-[30px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <div className='flex justify-between items-center bg-white shadow-block-shadow rounded-lg w-full p-4 gap-x-4'>
              <Checkbox name='weekly-form' className='weekly-forms !bg-transparent' checked disabled>
                <div className='flex items-center'>
                  <IconClipboardType className='ml-2.5 mr-1 min-w-6 min-h-6' />
                  <p className='text-16 text-black'>{formData.profile.tasks[0]?.name}*</p>
                </div>
              </Checkbox>
              <TooltipButton
                icon={<IconInfo color={COLORS.blue} />}
                text='Info'
                tooltipText='Clients must have already applied for entry to a partner SLH and been accepted for a term of residency at the SLH.' />
            </div>
            <div className='flex flex-col mt-6 pt-6 border-t border-color-divider w-full'>
              <p className='text-14 mb-1'>Programs Participations</p>
              {formData.profile.tasks.slice(1).map(({ name,task_id }) => {
                return (
                  <div className='flex justify-between items-center bg-white shadow-block-shadow rounded-lg w-full p-4 gap-x-4 mb-6' key={task_id}>
                    <Checkbox name='weekly-form' className='weekly-forms !bg-transparent' checked disabled>
                      <div className='flex items-center'>
                        <IconClipboardType className='ml-2.5 mr-1 min-w-6 min-h-6' />
                        <p className='text-16 text-black'>{name}</p>
                      </div>
                    </Checkbox>
                    <TooltipButton
                      icon={<IconInfo color={COLORS.blue} />}
                      text='Info'
                      tooltipText='Clients must have already applied for entry to a partner SLH and been accepted for a term of residency at the SLH.' />
                  </div>
                )
              })}
            </div>
          </div>}
        </div>
      </div>
    </Form>
  );
};

