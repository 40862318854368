import React, { useEffect, useState } from 'react';
import { ActivePartnersSLH } from "./components/activePartnersSLH";
import { endpoints } from 'api/endpoints';
import { getRequestData } from 'api/api';
import { ClientAnalytics, ScholarshipsAnalytics } from 'components';
import { ROLE_TYPES } from 'api/constants';

export const AdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    (async ()=>{
      const {data} = await getRequestData(endpoints.getDashboard, ROLE_TYPES.admin);
      
      setDashboardData(data);
    })();
  }, [])
  
  return dashboardData && (
    <div className="flex flex-col w-full gap-y-6">
      <ScholarshipsAnalytics scholarships={dashboardData.scolarships} detailsUrl='/admin/finances' />
      <ActivePartnersSLH partners={dashboardData.partners} />
      <ClientAnalytics clients={dashboardData.clients} seeAllUrl='/admin/clients' />
    </div>
  );
}

