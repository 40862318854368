import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Radio, } from 'antd';
import { FormInput, IconSmallArrow, OrangeButton, TooltipIcon, } from 'components';
import classNames from 'classnames';
import { createPartner } from 'api/api';
import { useNavigate } from 'react-router-dom';

export const CreatePartner = () => {
  const navigate = useNavigate();
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [formData, setFormData] = useState({
    slh: {
      name: "",
      region: "",
      rank: 1,
      gender: "",
      parent: "",
      bed_count: null,
      address: "",
      website: "",
      weekly_rate: null
    },
    supervisor: {
      name: "",
      phone: null,
      email: ""
    },
    manager: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      confirm_password: "",
      phone: null
    }
  });

  const onValuesChange = (changedFields) => {
    const prefixes = ['slh_', 'supervisor_', 'manager_'];

    for (const key in changedFields) {
      for (let prefix of prefixes) {
        if (key.startsWith(prefix)) {
          prefix = prefix.slice(0, -1);
          const propKey = key.slice(prefix.length + 1);
          setFormData({ ...formData, [prefix]: { ...formData[prefix], [propKey]: changedFields[key] } });
        }
      }
    }
  }

  useEffect(() => {
    const doesPasswordsMatch = formData.manager.password === formData.manager.confirm_password;

    const isFormValid =
      !!formData.slh.name &&
      !!formData.slh.bed_count &&
      !!formData.slh.weekly_rate &&
      !!formData.slh.gender &&
      !!formData.slh.rank &&
      !!formData.manager.password &&
      !!formData.manager.firstname &&
      !!formData.manager.email &&
      !!formData.supervisor.name &&
      !!formData.supervisor.email &&
      doesPasswordsMatch;
      setIsBtnDisabled(!isFormValid);
  }, [formData])


  const handleGenderChange = (genders) => {
    const selectedGenders = genders.join(',');

    setFormData({
      ...formData,
      slh: {
        ...formData.slh,
        gender: selectedGenders,
      }
    });
  }
  
  const handleRankChange = (e) => {
    const selectedRank = e.target.value;

    setFormData({
      ...formData,
      slh: {
        ...formData.slh,
        rank: selectedRank,
      }
    });
  }

  const handleFormSubmit = () => {
    createPartner(formData, () => {
      navigate('/admin/partners');
    });
  }

  return (
    <Form
      name='create-client-by-admin'
      className='flex flex-col w-full gap-6'
      onValuesChange={onValuesChange}
      requiredMark={false}
    >
      <div className='flex justify-between w-full'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate('/admin/partners')} className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
          <p className='nunito-title'>Add new partner (slh)</p>
        </div>
        <OrangeButton onClick={handleFormSubmit} label='Save' disabled={isBtnDisabled} />
      </div>
      <div className='flex flex-wrap items-start gap-6'>
        <div className='flex flex-col w-full base-block pt-[30px]'>
          <p className='nunito-title px-[30px] pb-[11px]'>SLH</p>
          <div className={classNames('flex flex-wrap relative border-t border-color-lightBlue p-[30px] pt-3 gap-x-8 gap-y-6',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',)}
          >
            <FormInput
              label="Name*"
              name="slh_name"
              placeholder="First Name"
              required
              errorMessage='Please enter valid name'
              className='w-[calc(50%-16px)]'
            />
            <FormInput
              label="Region"
              name="slh_region"
              placeholder="Enter region"
              className='w-[calc(50%-16px)]'
            />
            <div className='flex flex-col gap-y-2 w-[calc(50%-16px)]'>
              <div className='flex justify-between items-center'>
                <p className='text-14 font-medium'>Classification (Ranking)*</p>
                <TooltipIcon tooltipText='Tooltip about rank' />
              </div>
              <Radio.Group onChange={handleRankChange} className='w-full flex gap-x-4' name="slh_rank" defaultValue={'1'}>
                <Radio className='px-4 py-2.5 m-0 bg-white border border-color-orange rounded-xl flex-1' value={'1'}>1</Radio>
                <Radio className='px-4 py-2.5 m-0 bg-white border !border-color-orange rounded-xl flex-1' value={'2'}>2</Radio>
                <Radio className='px-4 py-2.5 m-0 bg-white border !border-color-orange rounded-xl flex-1' value={'3'}>3</Radio>
              </Radio.Group>
            </div>
            <FormInput
              label="Address"
              name="slh_address"
              placeholder="Enter address"
              className='w-[calc(50%-16px)]'
            />
            <div className='flex flex-col gap-y-2 w-[calc(50%-16px)]'>
              <p className='text-14 font-medium'>Gender*</p>
              <Checkbox.Group onChange={handleGenderChange} className='w-full gap-x-4' name='gender' required>
                <Checkbox className='px-4 py-2.5 bg-white border font-medium rounded-xl gap-x-2 flex-1' value={'M'}>Male</Checkbox>
                <Checkbox className='px-4 py-2.5 bg-white border font-medium rounded-xl gap-x-2 flex-1' value={'F'}>Female</Checkbox>
                <Checkbox className='px-4 py-2.5 bg-white border font-medium rounded-xl gap-x-2 flex-1' value={'O'}>Other</Checkbox>
              </Checkbox.Group>
            </div>
            <FormInput
              label="Website"
              name="slh_website"
              placeholder="Enter website"
              className='w-[calc(50%-16px)]'
            />
            <div className='flex gap-x-6 w-[calc(50%-16px)]'>
              <FormInput
                className='w-full'
                label="Parent"
                name="slh_parent"
                placeholder="Enter parent"
                required
              />
              <FormInput
                className='w-full'
                label="Bed Count*"
                name="slh_bed_count"
                type='number'
                placeholder="Enter total beds count"
                required
              />
            </div>
            <FormInput
              label="Weekly Rate*"
              name="slh_weekly_rate"
              type='number'
              placeholder="$"
              required
              className='w-[calc(50%-16px)]'
            />
          </div>
        </div>
        <div className='flex flex-col w-[calc(50%-12px)] base-block pt-[30px]'>
          <p className='nunito-title px-[30px] pb-[11px] uppercase'>SLH Manager</p>
          <div className={classNames('flex flex-wrap relative border-t border-color-lightBlue p-[30px] pt-3 gap-x-8 gap-y-6',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',)}
          >
            <FormInput
              label="Name*"
              name="manager_firstname"
              placeholder="Enter manager's name"
              required
              className='w-full'
              errorMessage='Please enter valid name'
            />
            <FormInput
              className='w-full'
              type='number'
              label="Phone"
              name="manager_phone"
              placeholder="Phone"
              errorMessage='Please enter valid phone'
              prefix='+1'
            />
            <FormInput
              type='email'
              className='w-full'
              label={
                <>
                  <p className='text-14 font-medium'>Email*</p>
                  <TooltipIcon tooltipText='This email will be used as SLH Managed Login credential' />
                </>
              }
              name="manager_email"
              placeholder="Email"
              required
              errorMessage='Please enter valid email'
            />
            <div className='flex gap-x-6 w-full'>
              <FormInput
                type='password'
                label={
                  <>
                    <p className='text-14 font-medium'>Password*</p>
                    <TooltipIcon tooltipText='This password will be used as SLH Managed Login credential' />
                  </>
                }
                name="manager_password"
                placeholder="Password"
                required
                errorMessage='Please enter right password'
                className='flex-1'
              />
              <FormInput
                type='password'
                label="Confirm Password*"
                name="manager_confirm_password"
                placeholder="Password"
                required
                errorMessage='Please enter right password'
                className='flex-1'
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col w-[calc(50%-12px)] base-block pt-[30px]'>
          <p className='nunito-title px-[30px] pb-[11px] uppercase'>SLH Superviser</p>
          <div className={classNames('flex flex-wrap relative border-t border-color-lightBlue p-[30px] pt-3 gap-x-8 gap-y-6',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',)}
          >
            <FormInput
              label="Name*"
              name="supervisor_name"
              placeholder="Enter supervisor's name"
              required
              className='w-full'
              errorMessage='Please enter valid name'
            />
            <FormInput
              className='w-full'
              label="Phone"
              type='number'
              name="supervisor_phone"
              placeholder="Phone"
              errorMessage='Please enter valid phone'
              prefix='+1'
            />
            <FormInput
              className='w-full'
              label="Email*"
              name="supervisor_email"
              placeholder="Email"
              required
              errorMessage='Please enter valid email'
            />
          </div>
        </div>
      </div>
    </Form>
  );
};
