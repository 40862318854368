import { getRequestData } from 'api/api';
import { ROLE_TYPES } from 'api/constants';
import { endpoints } from 'api/endpoints';
import { useAuth } from 'context/authContext';
import React, { createContext, useEffect, useState, useContext } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { partnerId, role } = useAuth();
  const [userDetails, setUserDetails] = useState();

  const storeUserDetails = (userData) => {
    setUserDetails(userData)
  }

  useEffect(() => {
    if (role) {
      (async () => {
        let { data } = await getRequestData(role === ROLE_TYPES.client ? endpoints.clientProfile : endpoints.getUserProfile, ROLE_TYPES[role]);
        if (partnerId) {
          data = { ...data, partner_id: partnerId }
        }
        setUserDetails(data);
      })();
    }
  }, [partnerId, role])


  return (
    <UserContext.Provider value={{ userDetails, storeUserDetails }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};