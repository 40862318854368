import React from 'react';
import { useAuth } from 'context/authContext';
import { Navigate } from "react-router-dom";
import { LoginForm } from 'components';
import HeroImage from 'assets/images/hero-hockey.png';
import { ROLE_TYPES } from 'api/constants';

export const LoginPage = () => {
  const { auth, role } = useAuth();
  const path = role === ROLE_TYPES.admin ? "/admin/dashboard" : "/manager/dashboard";

  return auth ? <Navigate to={path} /> : (
    <div className='flex relative justify-end h-full overflow-y-auto overflow-x-hidden bg-orange min-h-screen'>
      <div className='absolute z-10 top-0 bottom-0 left-[31%] -translate-x-1/2'>
        <LoginForm />
      </div>
      <div className='w-[69%] min-h-[52rem] relative'>
        <img className='absolute left-0 bottom-0 right-0 w-full h-full object-cover' src={HeroImage} alt='hero' />
      </div>
    </div>
  );
};

