export const API_BASE_URL = process.env.REACT_APP_PW25_SERVER_URL;
export const USER_ROLE = 'user_role';
export const PARTNER_ID = 'partner_id';
export const DATE_FORMAT = 'MM/DD/YYYY';

export const ROLE_TYPES = {
  admin: 'admin',
  slh_manager: 'slh_manager',
  client: 'client'
}

export const ACCESS_TOKEN_NAME = {
  admin: 'admin_auth_token',
  slh_manager: 'manager_auth_token',
  client: 'client_auth_token',
};

export const GENDER = {
  M: 'Male',
  F: 'Female',
  O: 'Other'
}

export const CLIENT_STATUS = {
  in_progress: 'in_progress',
  failed: 'relapse',
  completed: 'completed',
  not_started: 'not_started',
}

export const INVOICE_STATUS = {
  ready: 'Submitted',
  pending: 'Pending',
  submitted: 'Submitted',
  paid: 'Paid',
}
