import React from 'react'
import { twMerge } from 'tailwind-merge';

export function IconArrow({ onClick, className, color = '#00AC4F' }) {
  return (
    <svg onClick={onClick} className={twMerge('w-5 h-5 hover:[&_path]:stroke-orange', className)} fill="none" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.5 17L10.5 5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M4.66675 9.99996L10.5001 4.16663L16.3334 9.99996" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}
