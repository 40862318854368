import React, { useEffect, useMemo, useState } from 'react';
import { IconCalendar, IconClipboardType, IconEye, IconHandWave, IconSoberLiving, IconWallet, OrangeButton, SmallButton } from 'components';
import { COLORS } from 'utils/colors';
import CUP from 'assets/images/cup.png';
import { ClientTasks } from './components/clientTasks';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { CLIENT_STATUS, ROLE_TYPES } from 'api/constants';
import { formatPriceWithComma, getActiveWeekAndDaysSinceStart } from 'utils/helpers';

export const ClientDashboard = () => {
  const [dashboardData, setDashboardData] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.clientDashboard, ROLE_TYPES.client);
      if (data) setDashboardData(data);
    })();
  }, []);

  const activeDates = useMemo(() => {
    return dashboardData?.client && getActiveWeekAndDaysSinceStart(dashboardData?.client.start_date, dashboardData?.client.termination_date ? dashboardData?.client.termination_date : dashboardData?.client.end_date);
  }, [dashboardData])

  const storeWeeks = useMemo(() => {
    if (!dashboardData?.client) return []
    const weeksArr = [];

    for (let i = 1; i < 7; i++) {
      weeksArr.push(
        <div key={`week-${i}`} className={`flex items-center justify-center rounded-full w-[140px] h-[140px] ${i === dashboardData?.client.week ? 'bg-orange' : i > dashboardData?.client.week ? 'bg-lightGreen' : 'bg-green'}`}>
          <div className='flex flex-col justify-center items-center'>
            <p className={`text-5xl font-semibold ${i > dashboardData?.client.week ? 'text-green' : 'text-white'}`}>{i}</p>
            <p className={`text-xl ${i > dashboardData?.client.week ? 'text-green' : 'text-white'}`}>Week</p>
          </div>
        </div>
      )
    }

    return weeksArr;
  }, [dashboardData?.client])

  const findNotCompletedWeek = useMemo(() => {
    const notCompletedWeek = dashboardData?.client?.tasks.find(item => item.completed === false);
    return notCompletedWeek?.week;
  }, [dashboardData?.client?.tasks])

  return dashboardData && (
    <div className='flex flex-col w-full pb-20'>
      <div className='flex flex-col base-block w-full overflow-hidden'>
        <div className='flex w-full py-7 px-[30px]'>
          <div className='flex gap-x-2 items-center'>
            <div className='w-12 h-12 bg-divider rounded-full flex items-center justify-center'>
              <IconSoberLiving color={COLORS.blue} />
            </div>
            <b className='text-22 nunito-title '>{dashboardData.partner?.name}</b>
          </div>
          <div className='flex items-center base-block-light-shadow p-1 pl-6 mr-6 ml-auto'>
            <p className='text-14 text-lightBlue mr-3'>Total Granted</p>
            <div className='flex items-center bg-lightGreen py-1.5 pr-6 pl-3 rounded-md'>
              <IconWallet color={COLORS.green} />
              <p className='text-14 font-bold ml-2'>${formatPriceWithComma(dashboardData.client?.granted)}</p>
            </div>
            <p className='text-14 text-lightBlue mx-3'>For</p>
            <div className='flex items-center bg-yellow py-1.5 pr-6 pl-3 rounded-md'>
              <IconCalendar />
              <p className='text-14 font-bold ml-2'>{activeDates?.daysSinceStart} Days</p>
            </div>
          </div>
        </div>
        <div className='flex flex-col bg-green-gradient relative p-[30px] overflow-hidden'>
          <div className='flex flex-col z-10'>
            <div className='flex items-center gap-x-3.5'>
              <p className='uppercase text-5xl text-orange nunito'>HELLO {dashboardData.profile?.firstname},</p>
              <IconHandWave />
            </div>
            <b className='text-22 nunito-title mt-1'>See Your progress</b>
            <div className='flex gap-x-6 mt-8 mb-12'>
              {storeWeeks}
            </div>
          </div>
          <div className={`rounded-xl flex self-start items-center p-3 gap-x-12 h-[90px] bg-white shadow-block-shadow ${dashboardData.client?.status === CLIENT_STATUS.not_started ? 'px-10' : 'pl-10'}`}>
            <p className='text-16'>{dashboardData.client?.status === CLIENT_STATUS.not_started ? 'There are no new tasks' : 'Your weekly form is ready'}</p>
            {dashboardData.client?.status !== CLIENT_STATUS.not_started &&
              (
                dashboardData.client?.tasks[dashboardData.client?.week - 1]?.completed ?
                  <SmallButton
                    btnClassName='w-[145px] !h-[66px]'
                    icon={
                      <IconEye
                        className={'hover:text-darkGrey'}
                        color={COLORS.blue}
                      />
                    }
                    label='View'
                    href={`/client/weekly-forms/${dashboardData.client?.tasks[dashboardData.client?.week - 1]?.week}`}
                  /> :
                  <OrangeButton
                    href={`/client/weekly-forms/${findNotCompletedWeek}`}
                    btnClassName='!h-[66px]'
                    label='Start'
                    icon={<IconClipboardType className='ml-2.5 mr-1 min-w-6 min-h-6' />}
                  />
              )
            }
          </div>
          <div className='absolute bg-orange-gradient rounded-full w-[650px] h-[650px] top-1/2 right-0 translate-x-1/2 -translate-y-1/2' />
          <img className='absolute w-[426px] right-0 top-4' src={CUP} alt='cup' />
        </div>
      </div>
      <ClientTasks client={dashboardData.client} />
    </div>
  );
};

