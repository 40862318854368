import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconSmallArrow({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6 group', className)} fill="none" viewBox="0 0 8 14" xmlns="http://www.w3.org/2000/svg">
      <path className='group-hover:stroke-orange' d="M7 1L1 7L7 13" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}
