import React, { useEffect, useState, useCallback } from 'react';
import { SelectOption, IconPlus, IconSearch, OrangeButton, IconDownload, IconFilter, IconSmallArrow, SmallButton, IconFormMinus, IconFormPlus, CustomTable, IconReset } from 'components';
import { COLORS } from 'utils/colors';
import dayjs from 'dayjs';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { endpoints } from 'api/endpoints';
import classNames from 'classnames';
import { getRequestData } from 'api/api';
import { useNavigate } from 'react-router-dom';
import { CLIENT_STATUS, DATE_FORMAT, ROLE_TYPES } from 'api/constants';

export const AdminClients = () => {
  const navigate = useNavigate();
  const [clientsList, setClientsList] = useState();
  const [partnersList, setPartnersList] = useState();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPartner, setSelectedPartner] = useState(null);

  const columns = [
    {
      title: 'Client',
      dataIndex: 'client',
      width: 300,
      render: (_, record) => (
        <div className='flex items-center gap-x-1'>
          <img className='w-[30px] h-[30px] rounded-full' src={record.clientPhoto ? record.clientPhoto : DefaultAvatar} alt='client' />
          <p className='text-14 ml-1'>{record.client}</p>
          {record.isHaveNotCompletedTask ? <IconFormMinus className='min-w-4 min-h-4' /> : <IconFormPlus className='min-w-4 min-h-4' />}
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 120,
      render: (status, record) => {
        const statusAbbr = status.slice(0, 1).toUpperCase();
        return (
          <div className='flex items-center gap-x-3'>
            <p className='w-6 h-6 rounded-full bg-white shadow-block-shadow flex justify-center items-center'>
              <span className={classNames('uppercase text-xl leading-5 font-medium',
                { 'text-blue': statusAbbr === 'N' || statusAbbr === 'I' },
                { 'text-green': statusAbbr === 'C' },
                { 'text-red': statusAbbr === 'R' },
              )}>
                {status.slice(0, 1)}
              </span>
            </p>
            <p className='text-14 uppercase font-medium'>W{record.week ? record.week : 0}</p>
          </div>
        )
      }
    },
    {
      title: 'SLH',
      width: 300,
      dataIndex: 'partner',
      render: (partner) => <p className='text-14 font-medium text-ellipsis w-[300px] overflow-hidden whitespace-nowrap pr-4'>{partner}</p>,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      width: 120,
      showSorterTooltip: {
        target: 'sorter-icon',
      },
      // sorter: (a, b) => dayjs(a.date).isBefore(dayjs(b.date)) ? -1 : 1,
      render: (text) => dayjs(text).format(DATE_FORMAT),
      // sortIcon: ({ sortOrder }) => {
      //   return (
      //     <div className='flex flex-col absolute right-6'>
      //       <IconSmallArrow className={`rotate-90 h-2.5`} color={sortOrder === 'ascend' ? COLORS.blue : COLORS.lightBlue} />
      //       <IconSmallArrow className={`-rotate-90 h-2.5`} color={sortOrder === 'descend' ? COLORS.blue : COLORS.lightBlue} />
      //     </div>)
      // },
    },
    {
      title: 'Action',
      width: 100,
      dataIndex: 'action',
      className: 'action',
      render: (_, record) => <SmallButton onClick={() => navigate(`/admin/client/${record.userID}`)} label='Details' />,
    },
  ];


  const fetchClients = useCallback(async (params) => {
    const { data } = await getRequestData(endpoints.getClients(params), ROLE_TYPES.admin);
    if (data) {
      const clientsData = {
        currentPage: data.currentPage,
        pageCount: data.pageCount,
        pageSize: data.pageSize,
        clients: data.results.map(client => ({
          client: `${client.firstname} ${client.lastname}`,
          clientID: client.client_id,
          clientPhoto: client.avatar,
          status: client.status,
          partner: client.partner,
          partnerID: client.partner_id,
          startDate: client.start_date,
          userID: client.user_id,
          week: client.week,
          isHaveNotCompletedTask: client.tasks.some(task => task.completed === false),
        }))
      };
      setClientsList(clientsData);
    }
  }, [])

  useEffect(() => {
    fetchClients({ page: 1, pageSize: 10 });
  }, [fetchClients]);

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getPartners, ROLE_TYPES.admin);
      if (data) {
        const partnersData = data.results.map(partner => ({
          label: partner.name,
          value: partner.id
        }))
        setPartnersList(partnersData);
      }
    })();
  }, []);

  const handlePaginationChange = (page) => {
    const filters = { page };

    if (selectedStatus) {
      filters.status = selectedStatus;
    }
    if (selectedPartner) {
      filters.partner_id = selectedPartner;
    }

    fetchClients(filters);
  };


  const fetchClientsWithFilters = (status, partner_id) => {
    const filters = {};
    if (status) filters.status = status;
    if (partner_id) filters.partner_id = partner_id;
    fetchClients(filters);
  };

  const handleStatusSelectChange = (status) => {
    setSelectedStatus(status);
    fetchClientsWithFilters(status, selectedPartner);
  };

  const handleSLHSelectChange = (id) => {
    setSelectedPartner(id);
    fetchClientsWithFilters(selectedStatus, id);
  };

  const handleFiltersReset = () => {
    setSelectedStatus(null);
    setSelectedPartner(null);
    fetchClients({ page: 1, pageSize: 10 });
  };

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className='flex justify-between w-full'>
        <p className='nunito-title'>Clients</p>
        <div className='flex items-center gap-x-6'>
          {/* <Input
            className='search w-[300px] text-lightBlue text-16 rounded-ten bg-light'
            type='text'
            placeholder="Search"
            addonBefore={<IconSearch color={COLORS.lightBlue} />}
          // onSearch={onSearch}
          /> */}
          <OrangeButton onClick={() => navigate('/admin/create-client')} label='Add new client' icon={<IconPlus />} />
          {/* <IconDownload className='cursor-pointer' /> */}
        </div>
      </div>
      {clientsList && <><div className='flex items-center gap-x-3'>
        <div className='flex items-center gap-x-1 mr-3'>
          <IconFilter />
          <p className='text-16'>Filter</p>
        </div>
        <SelectOption
          onSelect={(value) => handleStatusSelectChange(value)}
          dropdownStyle={{ padding: '16px', background: 'white' }}
          required
          value={selectedStatus}
          suffixIcon={<IconSmallArrow color={COLORS.black} className='rotate-[-90deg] w-3 h-3' />}
          className='h-[40px] text-base font-medium base-block rounded-lg'
          popupClassName='min-w-[180px]'
          placeholder='Status'
          dropdownRender={originalNode => originalNode}
          options={[
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>In Progress</p>, value: CLIENT_STATUS.in_progress },
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>Completed</p>, value: CLIENT_STATUS.completed },
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>Relapsed</p>, value: CLIENT_STATUS.failed },
            { label: <p className='text-14 px-4 py-1.5 rounded-md'>Not Started</p>, value: CLIENT_STATUS.not_started },
          ]}
        />
        <SelectOption
          onSelect={(value) => handleSLHSelectChange(value)}
          dropdownStyle={{ padding: '16px', background: 'white' }}
          required
          value={selectedPartner}
          suffixIcon={<IconSmallArrow color={COLORS.black} className='rotate-[-90deg] w-3 h-3' />}
          className='h-[40px] text-base font-medium base-block rounded-lg'
          placeholder='Home'
          popupClassName='min-w-[400px]'
          dropdownRender={(originalNode) => originalNode}
          options={partnersList}
        />
        {(selectedPartner || selectedStatus) && <SmallButton onClick={handleFiltersReset} label='Reset All' icon={<IconReset />} />}
      </div>
        <CustomTable
          columns={columns}
          data={clientsList.clients}
          pageCount={clientsList.pageCount}
          current={clientsList.currentPage}
          pageSize={clientsList.pageSize}
          onChange={handlePaginationChange}
          rowKey={record => record.userID}
        />
      </>}
    </div>
  );
}

