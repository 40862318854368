import React from 'react';
import { Form, Button } from 'antd';

export const SmallButton = ({ href, icon, label, disabled, className, btnClassName, ...props }) => {

  return (
    <Form.Item className={className}>
      <Button href={href} type="primary" htmlType="button" disabled={disabled} className={btnClassName} {...props}>
        {icon}
        {label}
      </Button>
    </Form.Item>
  );
};
