import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconStopwatch({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6', className)} fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.667 3.33331H23.3337M20.0003 23.3333L25.0003 18.3333M33.3337 23.3333C33.3337 30.6971 27.3641 36.6666 20.0003 36.6666C12.6365 36.6666 6.66699 30.6971 6.66699 23.3333C6.66699 15.9695 12.6365 9.99998 20.0003 9.99998C27.3641 9.99998 33.3337 15.9695 33.3337 23.3333Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="3.33333" />
    </svg>
  )
}
