import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconClients({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.16055 10.8702C9.06055 10.8602 8.94055 10.8602 8.83055 10.8702C6.45055 10.7902 4.56055 8.84024 4.56055 6.44024C4.56055 3.99024 6.54055 2.00024 9.00055 2.00024C11.4505 2.00024 13.4405 3.99024 13.4405 6.44024C13.4305 8.84024 11.5405 10.7902 9.16055 10.8702Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M16.4093 3.99976C18.3493 3.99976 19.9093 5.56976 19.9093 7.49976C19.9093 9.38976 18.4093 10.9298 16.5393 10.9998C16.4593 10.9898 16.3693 10.9898 16.2793 10.9998" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M4.1607 14.5598C1.7407 16.1798 1.7407 18.8198 4.1607 20.4298C6.9107 22.2698 11.4207 22.2698 14.1707 20.4298C16.5907 18.8098 16.5907 16.1698 14.1707 14.5598C11.4307 12.7298 6.9207 12.7298 4.1607 14.5598Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M18.3398 20.0002C19.0598 19.8502 19.7398 19.5602 20.2998 19.1302C21.8598 17.9602 21.8598 16.0302 20.2998 14.8602C19.7498 14.4402 19.0798 14.1602 18.3698 14.0002" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  )
}
