import React from 'react';
import { COLORS } from 'utils/colors';
import { IconArrow } from 'components/icons/Arrow';

export const SuccessFailRate = ({ rate, withMonth }) => {
  rate = Number(rate);

  return (
    <div className='flex items-center'>
      {rate === 0 ?
        <span className='text-lightBlue text-12'>-</span>
        :
        <IconArrow color={rate < 0 ? COLORS.red : COLORS.green} className={rate < 0 ? 'rotate-180' : ''} />
      }
      <p className={`text-12 ml-1 ${rate === 0 ? 'text-lightBlue' : rate < 0 ? 'text-red' : 'text-green'}`}>
        {rate}%
        {withMonth && <span className='text-black ml-1'>this month</span>}
      </p>
    </div>
  )
}