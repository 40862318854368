import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconFormMinus({ onClick, className, color = '#D02B20' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-4 h-4', className)} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1294_8984)">
        <path d="M10.667 2.66689H12.0003C12.3539 2.66689 12.6931 2.80737 12.9431 3.05741C13.1932 3.30746 13.3337 3.6466 13.3337 4.00022V13.3336C13.3337 13.6872 13.1932 14.0263 12.9431 14.2764C12.6931 14.5264 12.3539 14.6669 12.0003 14.6669H4.00033C3.6467 14.6669 3.30756 14.5264 3.05752 14.2764C2.80747 14.0263 2.66699 13.6872 2.66699 13.3336V4.00022C2.66699 3.6466 2.80747 3.30746 3.05752 3.05741C3.30756 2.80737 3.6467 2.66689 4.00033 2.66689H5.33366M6.00033 9.33356H10.0003M6.00033 1.33356H10.0003C10.3685 1.33356 10.667 1.63203 10.667 2.00022V3.33356C10.667 3.70175 10.3685 4.00022 10.0003 4.00022H6.00033C5.63214 4.00022 5.33366 3.70175 5.33366 3.33356V2.00022C5.33366 1.63203 5.63214 1.33356 6.00033 1.33356Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1294_8984">
          <rect height="16" width="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
