import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconClipboardType({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6', className)} fill="none" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.3333 5.33329H23.9999C24.7072 5.33329 25.3854 5.61424 25.8855 6.11434C26.3856 6.61444 26.6666 7.29272 26.6666 7.99996V26.6666C26.6666 27.3739 26.3856 28.0521 25.8855 28.5522C25.3854 29.0523 24.7072 29.3333 23.9999 29.3333H7.99992C7.29267 29.3333 6.6144 29.0523 6.1143 28.5522C5.6142 28.0521 5.33325 27.3739 5.33325 26.6666V7.99996C5.33325 7.29272 5.6142 6.61444 6.1143 6.11434C6.6144 5.61424 7.29267 5.33329 7.99992 5.33329H10.6666M11.9999 16V14.6666H19.9999V16M14.6666 22.6666H17.3333M15.9999 14.6666V22.6666M11.9999 2.66663H19.9999C20.7363 2.66663 21.3333 3.26358 21.3333 3.99996V6.66663C21.3333 7.40301 20.7363 7.99996 19.9999 7.99996H11.9999C11.2635 7.99996 10.6666 7.40301 10.6666 6.66663V3.99996C10.6666 3.26358 11.2635 2.66663 11.9999 2.66663Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}
