import React, { useEffect, useState } from 'react';
import { IconClients, IconSoberLiving, IconWallet } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import { formatPriceWithComma } from 'utils/helpers';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { ROLE_TYPES } from 'api/constants';

export const FinancesSummary = ({ isManager }) => {
  const [data, setData] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getFinancesSummary, isManager ? ROLE_TYPES.slh_manager : ROLE_TYPES.admin);
      if (data) setData(data.summary);
    })();
  }, [isManager])

  return data && (
    <div className='flex flex-col px-[30px] pt-[30px] base-block w-full'>
      <div className='flex justify-between items-center mb-5'>
        <p className='nunito-title'>Summary</p>
        <div className='flex items-center'>
          <p className='text-14 text-lightBlue'>Total forecast<span className='mx-3'>|</span></p>
          <p className='text-24'>${formatPriceWithComma(data.total_forecast)}</p>
        </div>
      </div>
      <div className={classNames('flex border-t border-divider relative',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2')}>
        {!isManager && <div className='flex items-start justify-center flex-1 pt-6 pb-9'>
          <div className='bg-light rounded-full w-[84px] h-[84px] flex items-center justify-center'>
            <IconSoberLiving className='w-10 h-10' color={COLORS.blue} />
          </div>
          <div className='flex flex-col ml-5'>
            <p className='text-14 text-lightBlue'>Active Homes</p>
            <p className='text-24 text-black my-1'>{data.active_homes}</p>
          </div>
        </div>}
        <div className={classNames('flex items-start justify-center flex-1 pt-6 pb-9')}>
          <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
            <IconClients className='w-10 h-10' color={COLORS.green} />
          </div>
          <div className='flex flex-col ml-5'>
            <p className='text-14 text-lightBlue'>Active Clients</p>
            <p className='text-24 text-black my-1'>{data.active_clients}</p>
          </div>
        </div>
        <div className='flex items-start justify-center flex-1 pt-6 pb-9'>
          <div className='bg-yellow rounded-full w-[84px] h-[84px] flex items-center justify-center'>
            <IconWallet className='w-10 h-10' color={COLORS.orange} />
          </div>
          <div className='flex flex-col ml-5'>
            <p className='text-14 text-lightBlue'>Week to day</p>
            <p className='text-24 text-black my-1'>${formatPriceWithComma(data.week_to_day)}</p>
          </div>
        </div>
        <div className='flex items-start justify-center flex-1 pt-6 pb-9'>
          <div className='bg-pink rounded-full w-[84px] h-[84px] flex items-center justify-center'>
            <IconWallet className='w-10 h-10' color={COLORS.red} />
          </div>
          <div className='flex flex-col ml-5'>
            <p className='text-14 text-lightBlue'>Week forecast</p>
            <p className='text-24 text-black my-1'>${formatPriceWithComma(data.week_forecast)}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

