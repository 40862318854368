import axios from 'axios';
import { endpoints } from './endpoints';
import { getAuthToken } from 'utils/helpers';
import { ROLE_TYPES } from './constants';
import { toast } from 'react-toastify';

//Fetch data 
export const getRequestData = async (url, domain, onSuccess) => {
  const token = getAuthToken(domain);

  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return { data: response.data, error: null };
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

// Update user image
export const updateUserImage = (userID, image, domain) => {
  const token = getAuthToken(domain);
  const formData = new FormData();
  formData.append('image', image);

  axios.post(
    endpoints.uploadAvatar(userID),
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data'
      },
    }
  ).then((response) => {
    console.log(response, 'image upload response');
  }).catch((error) => {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  });
};

// Upload external document
export const uploadDocument = (userID, document, domain) => {
  const token = getAuthToken(domain);
  const formData = new FormData();
  formData.append('doc', document);

  return axios.post(
    endpoints.uploadDocument(userID),
    formData,
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }
  )
    .then((response) => {
      console.log(response, 'document upload response');
      return response.data;
    })
    .catch((error) => {
      if (typeof error.response.data === 'string') {
        toast.error(error.response.data);
      }
      toast.error(error.response.data.error);
    });
};

//Create partner
export const createPartner = async (formData, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.admin);
  try {
    const response = await axios.post(endpoints.registerPartner, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};
//Update partner
export const updatePartner = async (formData, userID, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.admin);
  try {
    const response = await axios.put(endpoints.updatePartner(userID), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

//Create client
export const createClient = async (formData, domain, onSuccess) => {
  const token = getAuthToken(domain);

  try {
    const response = await axios.post(endpoints.registerClient, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};
//Update client
export const updateClient = async (formData, userID, domain, onSuccess) => {
  const token = getAuthToken(domain);

  const data = {
    firstname: formData.profile.firstname,
    lastname: formData.profile.lastname,
    gender: formData.profile.gender,
    phone: `+${formData.profile.phone}`,
    pronoun: formData.profile.identifyas,
    birthdate: formData.profile.birthdate,
  }

  try {
    const response = await axios.put(endpoints.updateUser(userID), data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

//Terminate client
export const terminateClient = async (userID, formData, domain, onSuccess) => {
  const token = getAuthToken(domain);
  try {
    const response = await axios.post(endpoints.terminateUser(userID), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response);
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};
//Submit client feedback
export const clientSubmitFeedback = async (formData, domain, onSuccess) => {
  const token = getAuthToken(domain);
  try {
    const response = await axios.post(endpoints.clientSubmitFeedback, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

//Submit invoice
export const submitInvoiceByManager = async (invoice_id, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.slh_manager);

  try {
    const response = await axios.post(endpoints.submitInvoice(invoice_id), {}, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data)
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

//Submit additional payment
export const addAdditionalPaymentsByManager = async (invoice_id, formData, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.slh_manager);

  try {
    const response = await axios.post(endpoints.addAdditionalPayments(invoice_id), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data)
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};
//Remove additional payment
export const removeAdditionalPaymentsByManager = async (invoice_id, payment_id, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.slh_manager);

  try {
    const response = await axios.delete(endpoints.removeAdditionalPayments(invoice_id, payment_id), {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response)
    }
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

// Update bed occupancy
export const updateBeds = async (formData, partnerID, onSuccess) => {
  const token = getAuthToken(ROLE_TYPES.slh_manager);
  try {
    const response = await axios.put(endpoints.updateBedsOccupancy(partnerID), formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (onSuccess) {
      onSuccess(response.data);
    }
    return response.data;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

// Forgot password
export const forgotPassword = async (email, domain) => {
  try {
    const response = await axios.post(domain === ROLE_TYPES.client ? endpoints.clientForgotPassword : endpoints.forgotPassword, { email });

    return response;

  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

// Forgot password
export const resetPassword = async (formData, domain) => {
  try {
    const response = await axios.post(domain === ROLE_TYPES.client ? endpoints.clientResetPassword : endpoints.resetPassword, formData);

    return response;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};

// Verify code
export const verifyCode = async (formData, domain) => {
  try {
    const response = await axios.post(domain === ROLE_TYPES.client ? endpoints.clientVerifyCode : endpoints.verifyCode, formData);
    
    return response;
  } catch (error) {
    if (typeof error.response.data === 'string') {
      toast.error(error.response.data);
    }
    toast.error(error.response.data.error);
  }
};