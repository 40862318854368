import React, { useEffect, useMemo, useState } from 'react';
import { ROLE_TYPES } from 'api/constants';
import { endpoints } from 'api/endpoints';
import { getRequestData } from 'api/api';
import { WeeklyRegularForm } from './components/weeklyRegularForm';
import { useLocation } from 'react-router-dom';
import { getDynamicParamsFromPath } from 'utils/helpers';

export const WeeklyFormsAdmin = () => {
  const location = useLocation();
  const [profileData, setProfileData] = useState(null);

  const params = useMemo(() => {
    return getDynamicParamsFromPath(location.pathname);
  }, [location.pathname])

  useEffect(() => {
    const [client_id, week_id] = params;

    (async () => {
      const { data } = await getRequestData(endpoints.getUserTasks(client_id, week_id), ROLE_TYPES.admin);
      if (data) setProfileData(data);
    })();
  }, [params])

  return profileData && (
    <div className='flex justify-center w-full gap-x-10'>
      <WeeklyRegularForm clientTasks={profileData} />
    </div>
  );
};

