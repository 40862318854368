import React, { useState, useMemo } from 'react';
import { CustomCollapse, IconFormMinus, IconFormPlus, IconSmallArrow } from 'components';
import classNames from 'classnames';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { COLORS } from 'utils/colors';
import { formatPriceWithComma } from 'utils/helpers';
import { CLIENT_STATUS } from 'api/constants';
import { SingleWeekInRow } from './singleWeekInRow';

export const SingleClientRow = ({ client, domain }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const statusAbbr = client.status.slice(0, 1).toUpperCase();

  const renderWeekData = useMemo(() => {
    let clientWeeks = client.weeks;

    if (client.weeks.length !== 7) {
      clientWeeks = [...clientWeeks, {
        days: [
          {
            day_number: 0,
            date: null,
            day_status: null
          },
          {
            day_number: 1,
            date: null,
            day_status: null
          },
          {
            day_number: 2,
            date: null,
            day_status: null
          },
          {
            day_number: 3,
            date: null,
            day_status: null
          },
          {
            day_number: 4,
            date: null,
            day_status: null
          },
          {
            day_number: 5,
            date: null,
            day_status: null
          },
          {
            day_number: 6,
            date: null,
            day_status: null
          }
        ],
        week_number: 7,
        day_status: null,
        granted: 0.00
      }]
    }

    return clientWeeks.map((week, weekIndex) => (
      <SingleWeekInRow weekIndex={weekIndex} week={week} client={client} domain={domain} key={Math.random()} />
    ));
  }, [client, domain])

  return (
    <div className='flex flex-col bg-white rounded-xl shadow-block-shadow pt-3.5'>
      <div className='flex items-center relative px-2 pb-3.5'>
        <div className='flex items-center gap-x-1 pr-4 w-[25%]'>
          <img className='w-[30px] h-[30px] rounded-full' src={client.avatar ? client.avatar : DefaultAvatar} alt='client' />
          <p className='text-14 ml-1 line-clamp-2	'>{client.firstname} {client.lastname}</p>
          {client.client_id % 2 === 0 ? <IconFormMinus /> : <IconFormPlus />}
        </div>
        <p className='text-14 font-medium text-ellipsis w-[28%] overflow-hidden whitespace-nowrap pr-4'>{client.partner}</p>
        <div className='flex items-center gap-x-1 pr-4 w-[17%]'>
          <span className={classNames('text-14 font-medium capitalize whitespace-nowrap',
            { 'text-blue': statusAbbr === 'N' || statusAbbr === 'I' },
            { 'text-green': statusAbbr === 'C' },
            { 'text-red': statusAbbr === 'R' },
          )}>
            {client.status.replace('_', ' ')}
          </span>
          {(client.status === CLIENT_STATUS.failed || client.status === CLIENT_STATUS.in_progress) && <p className='text-14 uppercase font-medium text-lightBlue'>{`(W${client.week})`}</p>}
        </div>
        <p className='font-medium text-14 w-[10%]'>${formatPriceWithComma(client.start)}</p>
        <p className='font-medium text-14 w-[10%]'>${formatPriceWithComma(client.granted)}</p>
        <p className='font-medium text-14 w-[10%]'>${formatPriceWithComma(client.remaining)}</p>
        <IconSmallArrow onClick={() => setIsCollapsed(!isCollapsed)} color={COLORS.black} className={`absolute top-[calc(50%-7px)] -translate-y-1/2 right-2 w-5 h-5 p-1 duration-100 ${isCollapsed ? 'rotate-[90deg]' : 'rotate-[-90deg]'}`} />
      </div>
      <CustomCollapse isOpen={isCollapsed}>
        <div className='flex'>
          {renderWeekData}
        </div>
      </CustomCollapse>
    </div>
  );
};
