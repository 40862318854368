import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconFlag({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6', className)} fill="none" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 22.5V2.5L17 7.5L7 12.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}
