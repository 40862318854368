import { IconFlag } from 'components';
import React, { useMemo } from 'react';
import { SingleClientTaskRow } from './singleClientTaskRow';
import { formatDate } from 'utils/helpers';

export const ClientTasks = ({ client }) => {

  const storeTasks = useMemo(() => {
    for (let i = 0; i < 6; i++) {
      if (client?.tasks[i] === undefined) {
        client.tasks[i] = {
          completed: false,
          week: i + 1,
          task_id: null,
          form_id: null,
        }
      }
    }

    return client?.tasks.map((task, index) => {
      return (
        <SingleClientTaskRow
          key={task.task_id || Math.random().toFixed(3)}
          weekIndex={index + 1}
          task={task}
          startDate={client.start_date}
          clientStatus={client.status}
          activeWeek={client.week}
        />
      )
    })
  }, [client?.start_date, client?.status, client?.tasks, client?.week])

  return client && (
    <div className='flex flex-col bg-white base-block mt-6'>
      <div className='flex justify-between items-center p-[30px] relative border-b border-divider after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-divider after:bg-white after:z-10 after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:translate-y-1/2'>
        <p className='nunito-title'>Your Tasks</p>
        <div className='flex items-center gap-x-12'>
          <div className='flex items-center bg-white p-1 pl-2 rounded-ten shadow-block-shadow-light'>
            <IconFlag />
            <p className='text-14 ml-1.5 mr-3'>Start</p>
            <div className='flex items-center bg-yellow py-1.5 px-3 rounded-md'>
              <p className='text-14 font-bold'>{formatDate(client?.start_date)}</p>
            </div>
          </div>
          <div className='flex items-center bg-white p-1 pl-2 rounded-ten shadow-block-shadow-light'>
            <IconFlag />
            <p className='text-14 ml-1.5 mr-3'>End</p>
            <div className='flex items-center bg-lightGreen py-1.5 px-3 rounded-md'>
              <p className='text-14 font-bold'>{formatDate(client.end_date)}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col py-5 px-[30px] gap-y-4'>
        <div className='flex items-center'>
          <p className='text-lightBlue text-14 w-[20%]'>
            Week
          </p>
          <div className='w-4/5 flex items-center px-4 gap-x-8'>
            <p className='w-1/4 text-lightBlue text-14'>Forms</p>
            <p className='w-1/4 text-lightBlue text-14'>Granted</p>
            <p className='w-1/4 text-lightBlue text-14'>Completed</p>
            <p className='w-1/4 text-lightBlue text-14'>Status</p>
          </div>
        </div>
        {storeTasks}
      </div>
    </div>
  );
};
