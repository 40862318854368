import React from 'react';
import { Modal } from 'antd';
import { IconCloseButton } from 'components/icons/CloseButton';
import { COLORS } from 'utils/colors';
import { IconSoberLiving } from 'components/icons/SoberLiving';

const GlobalModal = ({
  onClose,
  content,
  children,
  title,
  width = 650,
  closable = true,
  centered = true,
  open = false,
  titleLeft,
  partnerName,
  partnerAddress
}) => {

  return (
    <Modal
      open={open}
      onCancel={onClose}
      maskClosable
      width={width}
      footer={null}
      closable={closable}
      centered={centered}
      style={{ 'borderRadius': '60px', 'overflow': 'hidden' }}
      destroyOnClose
      closeIcon={<IconCloseButton className='w-9 h-9' color={COLORS.lightBlue} />}
    >
      <div className='flex relative border-b before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:bottom-0 before:left-1/2 before:-translate-x-1/2 before:translate-y-1/2'>
        {partnerName &&
          <div className='flex items-center absolute top-1/2 left-[30px] -translate-y-1/2'>
            <div className='bg-light rounded-full w-12 h-12 p-3 flex items-center justify-center'>
              <IconSoberLiving className='w-12 h-12' color={COLORS.blue} />
            </div>
            <div className='flex flex-col ml-3 max-w-64'>
              <p className='nunito text-base mb-0.5'>{partnerName}</p>
              <p className='text-14 text-lightBlue'>{partnerAddress}</p>
            </div>
          </div>}
        <p className={`nunito-title pt-[30px] pb-6 flex-1 ${titleLeft ? 'text-left pl-[30px]' : 'text-center'}`}>{title}</p>
      </div>
      {content || children}
    </Modal>
  );
};

export default GlobalModal;
