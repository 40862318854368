import { Tooltip } from "antd";
import { IconBed } from "components";
import { IconPatient } from "components/icons/Patient";
import { memo as Memo } from "react";
import { COLORS } from "utils/colors";

export const SingleBed = ({ isBedOccupied, toggleBed, inEdit, isPWClient }) => {
  const tooltipBody = () => {
    if (!inEdit) return '';

    if (isPWClient) {
      return (
        <div className="px-4 py-3 bg-black rounded-lg overflow-hidden">
          <p className="text-14 text-white">Occupied by PF25 Client</p>
        </div>
      )
    }

    return (
      <div className="p-4 bg-white rounded-xl shadow-block-shadow">
        <div className="flex gap-x-1 px-2 py-1 border border-divider rounded-md">
          <IconBed color={isBedOccupied ? COLORS.red : COLORS.green} />
          <p className="text-14 text-black">Mark as {isBedOccupied ? 'Available' : 'Occupied'}</p>
        </div>
      </div>
    );
  }

  return (
    <Tooltip
      destroyTooltipOnHide
      title={tooltipBody()}
      color={isPWClient ? 'black' : 'transparent'}
      overlayInnerStyle={{ padding: '0', borderRadius: isPWClient ? '8px' : '0', boxShadow: 'none' }}
    >
      <div onClick={inEdit && !isPWClient ? toggleBed : undefined} className={`relative ${isBedOccupied ? isPWClient ? 'bg-lightGrey' : 'bg-pink' : 'bg-lightGreen'} ${inEdit && !isPWClient ? 'hover:border-2 hover:border-blue cursor-pointer' : ''} rounded-full w-10 h-10 flex items-center  justify-center`}>
        {isPWClient && <IconPatient color={COLORS.orange} className='absolute top-0 right-0 translate-x-1/3 -translate-y-1/4 p-1 w-5 h-5 rounded-full bg-white' />}
        <IconBed color={isBedOccupied ? isPWClient ? COLORS.darkGrey : COLORS.red : COLORS.green} className='pointer-events-none' />
      </div>
    </Tooltip>
  );
}

export default Memo(SingleBed);
