import React from 'react'
import { twMerge } from 'tailwind-merge';

export function IconClientFailure({ onClick, className, color = '#D02B20' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6', className)} fill="none" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
      <path d="M26 29L32 35" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M32 29L26 35" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
      <path d="M20.2671 18.117C20.1004 18.1003 19.9004 18.1003 19.7171 18.117C15.7504 17.9837 12.6004 14.7337 12.6004 10.7337C12.5837 6.65035 15.9004 3.33368 19.9837 3.33368C24.0671 3.33368 27.3837 6.65035 27.3837 10.7337C27.3837 14.7337 24.2171 17.9837 20.2671 18.117Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
      <path d="M19.9832 36.3498C16.9498 36.3498 13.9332 35.5832 11.6332 34.0498C7.59982 31.3498 7.59982 26.9498 11.6332 24.2665C16.2165 21.1998 23.7332 21.1998 28.3165 24.2665" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2.5" />
    </svg>
  )
}
