import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/authContext';
import { ROLE_TYPES } from 'api/constants';
import { useGetAuthToken } from 'hooks/useGetAuthToken';
import { useEffect } from 'react';

const ProtectedClientRoute = ({ children }) => {
  const { role } = useAuth();
  const token = useGetAuthToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate('/');
      return;
    }

    if (role !== ROLE_TYPES.client) {
      navigate(-1);
    }
  }, [token, role, navigate]);

  if (role === ROLE_TYPES.client) {
    return children;
  }

  return null; 
};

export default ProtectedClientRoute;