import React from 'react';
import { Form, Button } from 'antd';

export const OrangeButton = ({ icon, label, disabled, className, type, href, btnClassName, ...props }) => {

  return (
    <Form.Item className={className}>
      <Button href={href} htmlType={type} disabled={disabled} className={btnClassName} {...props}>
        {icon}
        {label}
      </Button>
    </Form.Item>
  )
};
