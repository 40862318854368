import React from 'react';
import { isInRoute } from 'utils/helpers';
import { IconLogout } from 'components';
import { useAuth } from 'context/authContext';
import { useUser } from 'context/userContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DefaultAvatar from 'assets/images/default-avatar.png';
import Logo from 'assets/images/logo.svg';

export const ClientHeader = () => {
  const { logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useUser();

  return (
    <div className="flex justify-between px-10 py-4 shadow-block-shadow rounded-block-radius-sm bg-white">
      <img onClick={() => navigate('/client/dashboard')} className='w-32 cursor-pointer' src={Logo} alt='pw25 logo' />
      <div className='flex justify-between items-center'>
        <Link to='/client/me' className={`flex items-center p-2 pr-4 mr-4 rounded-[40px] ${isInRoute(location.pathname, '/me') ? 'bg-yellow' :''}`}>
          <img className='w-[42px] h-[42px] rounded-full' src={userDetails?.avatar ? userDetails.avatar : DefaultAvatar} alt='profile' />
          <div className='flex flex-col ml-3'>
            <p className='text-black text-14 font-medium'>{userDetails?.profile?.firstname} {userDetails?.profile?.lastname}</p>
          </div>
        </Link>
        <IconLogout className='cursor-pointer' onClick={logout} />
      </div>
    </div>
  );
}
