import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconPaperclip({ onClick, className, color = '#4318FF' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6 group', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path className='group-hover:stroke-orange' d="M21.44 11.0499L12.25 20.2399C11.1242 21.3658 9.59723 21.9982 8.00505 21.9982C6.41286 21.9982 4.88589 21.3658 3.76005 20.2399C2.6342 19.1141 2.00171 17.5871 2.00171 15.9949C2.00171 14.4027 2.6342 12.8758 3.76005 11.7499L12.33 3.17991C13.0806 2.42802 14.0991 2.00509 15.1615 2.00415C16.2239 2.00321 17.2432 2.42435 17.995 3.17491C18.7469 3.92547 19.1699 4.94398 19.1708 6.00638C19.1717 7.06877 18.7506 8.08802 18 8.83991L9.41005 17.4099C9.03476 17.7852 8.52577 17.996 7.99505 17.996C7.46432 17.996 6.95533 17.7852 6.58005 17.4099C6.20476 17.0346 5.99393 16.5256 5.99393 15.9949C5.99393 15.4642 6.20476 14.9552 6.58005 14.5799L15.07 6.09991" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}
