import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconStar({ onClick, className, color = '#FDB513' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6 group', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 18.7494L17.3348 21.8186C18.3118 22.3811 19.5073 21.5496 19.2502 20.498L17.8362 14.7264L22.554 10.8378C23.4153 10.1286 22.9525 8.78352 21.8212 8.69792L15.6123 8.19657L13.1827 2.74286C12.7456 1.75238 11.2544 1.75238 10.8173 2.74286L8.38773 8.18435L2.17875 8.6857C1.04751 8.77129 0.584727 10.1164 1.44601 10.8256L6.16381 14.7141L4.74976 20.4858C4.49266 21.5374 5.68818 22.3689 6.66516 21.8064L12 18.7494Z" fill={color} />
    </svg>
  )
}
