import React, { useMemo } from 'react';
import { IconClipboardType, IconEye, IconFlag, IconMark, IconStopwatch, OrangeButton, SmallButton } from 'components';
import { COLORS } from 'utils/colors';
import dayjs from 'dayjs';
import { CLIENT_STATUS, DATE_FORMAT } from 'api/constants';

export const SingleClientTaskRow = ({ task, weekIndex, startDate, clientStatus, activeWeek }) => {
  const initialDate = dayjs(startDate);
  const datePlusWeekIndex = initialDate.add(weekIndex * 7, 'day');

  const storeTaskButtons = useMemo(() => {
    if (task.completed) {
      return (
        <div className='flex items-center justify-between gap-x-6 w-full'>
          <p className='text-14 text-green'>Completed</p>
          <SmallButton icon={<IconEye className={'hover:text-darkGrey'} color={COLORS.blue} />} label='View' href={`/client/weekly-forms/${weekIndex}`} />
        </div>
      )
    } else if (task.task_id) {
      return (<OrangeButton
        href={`/client/weekly-forms/${weekIndex}`}
        label='Start'
        icon={<IconClipboardType className='min-w-6 min-h-6' />}
      />)
    }
    return <p className='text-14 font-medium'>There are no new tasks</p>
  }, [task.completed, task.task_id, weekIndex])


  if (clientStatus === CLIENT_STATUS.not_started || weekIndex > activeWeek) {
    return (
      <div className='flex bg-lightGrey rounded-lg shadow-block-shadow h-[76px] overflow-hidden pointer-events-none'>
        <div className={`flex items-center w-1/5  gap-x-4 px-5 bg-grey`}>
          <IconFlag color={COLORS.darkGrey} className="min-w-7" />
          <p className="text-24 font-normal text-darkGrey whitespace-nowrap">
            Week {weekIndex}
          </p>
        </div>
        <div className='w-4/5 flex items-center px-4 gap-x-8'>
          <div className='w-1/4 flex items-center gap-x-1'>
            <IconClipboardType />
            <p className='flex text-16 text-lightBlue'>-</p>
          </div>
          <p className='text-14 font-medium w-1/4 text-lightBlue'>-</p>
          <p className='text-14 font-medium w-1/4 text-lightBlue'>-</p>
          <div className='w-1/4 flex justify-end'>
            <div className='flex items-center justify-end gap-x-6'>
              <p className='text-14 text-lightBlue'>Upcoming</p>
              <SmallButton btnClassName='!bg-grey !text-darkGrey' label='Start' />
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='flex bg-white rounded-lg shadow-block-shadow h-[76px] overflow-hidden'>
      <div className={`flex items-center w-[20%] gap-x-4 px-5 ${weekIndex < activeWeek ? 'bg-green' : 'bg-orange'}`}>
        {weekIndex < activeWeek ?
          <IconMark color={COLORS.white} className="min-w-7" />
          : <IconStopwatch className="min-w-7" />}
        <p className={`text-24 font-normal whitespace-nowrap ${weekIndex < activeWeek ? 'text-white' : 'text-black'}`}>
          Week {weekIndex}
        </p>
      </div>
      <div className='w-4/5 flex items-center px-4 gap-x-8'>
        <div className='w-1/4 flex items-center gap-x-1'>
          <IconClipboardType />
          <p className='flex text-16 gap-x-1'><span className='text-green'>{Number(task.completed)}</span> (1)</p>
        </div>
        <p className='text-14 font-medium w-1/4'>{weekIndex < activeWeek ? '$500' : '-'}</p>
        <p className='text-14 font-medium w-1/4'>{weekIndex < activeWeek ? datePlusWeekIndex.format(DATE_FORMAT) : '-'}</p>
        <div className='w-1/4 flex justify-end'>{storeTaskButtons}</div>
      </div>
    </div>
  );
};

