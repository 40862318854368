import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconHandWave({ onClick, className }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-12 h-12', className)} fill="none" viewBox="0 0 48 49" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_378_11163)">
        <path d="M15.3003 8.42574L15.3003 8.42579C16.688 7.06588 18.8846 7.06601 20.2728 8.39861L20.2801 8.40568L20.2873 8.41289C21.6088 9.73436 21.6561 11.8392 20.4292 13.2168L28.7531 4.89289C30.1275 3.51847 32.3153 3.52863 33.7002 4.88579C34.9712 6.13137 35.0884 8.07921 34.0517 9.45429L36.4931 7.01289C37.8637 5.64237 40.0768 5.64237 41.4473 7.01289C42.8177 8.38329 42.8179 10.5962 41.4477 11.9667C41.4476 11.9668 41.4475 11.967 41.4473 11.9671L39.5858 13.8341C40.9762 12.7334 43.0039 12.8094 44.2873 14.0929C45.6579 15.4634 45.6579 17.6766 44.2873 19.0471L31.9144 31.42L31.9202 31.4258L40.0522 23.2938C40.0525 23.2935 40.0528 23.2932 40.0531 23.2929C40.3781 22.9674 40.764 22.7091 41.1888 22.5328C41.614 22.3564 42.0699 22.2655 42.5302 22.2655C42.9906 22.2655 43.4465 22.3564 43.8717 22.5328C44.2965 22.7091 44.6824 22.9674 45.0073 23.2929L15.3003 8.42574ZM15.3003 8.42574L15.2924 8.43366L6.11313 17.6329C6.11294 17.6331 6.11275 17.6333 6.11257 17.6335C-0.717397 24.464 -0.71721 35.5568 6.11313 42.3871C12.9437 49.2176 24.0368 49.2176 30.8673 42.3871L30.8683 42.3861L45.0011 28.2134C46.3909 26.871 46.3667 24.6528 45.0078 23.2933L15.3003 8.42574ZM47.0002 34.5V33.5H46.0002H43.0002H42.0002V34.5C42.0002 38.9477 38.4479 42.5 34.0002 42.5H33.0002V43.5V46.5V47.5H34.0002C41.1725 47.5 47.0002 41.6723 47.0002 34.5ZM1.00023 14.5V15.5H2.00023H5.00023H6.00023V14.5C6.00023 10.0523 9.55252 6.5 14.0002 6.5H15.0002V5.5V2.5V1.5H14.0002C6.82795 1.5 1.00023 7.32772 1.00023 14.5ZM14.8872 26.1823C14.8019 27.5476 14.3191 28.8502 13.5091 29.9347L13.5033 29.9289C14.4015 28.7264 14.895 27.2586 14.895 25.74C14.895 23.8881 14.1611 22.1119 12.8544 20.8L12.8932 20.7613L13.4303 21.4532C14.4755 22.7996 14.9936 24.4811 14.8872 26.1823ZM30.5577 22.8885L28.398 25.063L28.3938 25.0586L30.5577 22.8885Z" fill="#FDB513" stroke="black" strokeWidth="2" />
      </g>
      <defs>
        <clipPath id="clip0_378_11163">
          <rect height="48" width="48" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
