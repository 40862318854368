import React, { useEffect, useState } from 'react';
import { FormInput, IconSoberLiving } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import { ROLE_TYPES } from 'api/constants';
import { endpoints } from 'api/endpoints';
import { getRequestData } from 'api/api';
import dayjs from 'dayjs';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { WeeklyRegularForm } from './components/weeklyRegularForm';

export const WeeklyForms = () => {
  const [profileData, setProfileData] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.clientProfile, ROLE_TYPES.client);
      if (data) setProfileData(data);
    })();
  }, [])
  
  return profileData && (
    <div className='flex justify-center w-full gap-x-10'>
      <div className='flex flex-col gap-y-6 w-[442px]'>
        <div className='flex shadow-block-shadow bg-white rounded-block-radius-sm p-4 relative'>
          <div className='flex items-center gap-x-3'>
            <div className='bg-light p-3 rounded-full'>
              <IconSoberLiving color={COLORS.blue} />
            </div>
            <div className='flex flex-col gap-y-0.5'>
              <p className='nunito-title text-base leading-6 line-clamp-2	flex-1'>{profileData.partner?.name}</p>
              <p className='text-14 text-lightBlue flex-1'>{profileData.partner?.address}</p>
            </div>
          </div>
          <span className='absolute right-0 top-1/2 -translate-y-1/2 px-3.5 py-2.5 text-14 font-medium border border-divider bg-light rounded-l-[20px]'>{profileData.partner?.rank}</span>
        </div>
        <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[442px]'>
          <p className='nunito-title px-[30px] pb-[11px]'>Personal Information</p>
          <div className={classNames('relative w-full flex flex-col pt-14 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <img className='self-center w-[190px] h-[190px] rounded-xl' src={profileData.profile?.avatar ? profileData.profile?.avatar : DefaultAvatar} alt='profile' />
            <FormInput
              label="Full Name"
              className='w-full'
              value={`${profileData.profile?.firstname} ${profileData.profile?.lastname}`}
              readOnly
            />
            <div className='flex flex-col gap-y-1 w-full'>
              <p className='text-14 font-medium'>Date Of Birth</p>
              <div className='flex gap-x-3'>
                <FormInput
                  inputClassName='text-center text-base'
                  value={new Date(profileData.profile?.birthdate).getDate()}
                  readOnly
                />
                <FormInput
                  inputClassName='text-center text-base'
                  value={dayjs().month(new Date(profileData.profile?.birthdate).getMonth()).format('MMMM')}
                  readOnly
                />
                <FormInput
                  inputClassName='text-center text-base'
                  value={new Date(profileData.profile?.birthdate).getFullYear()}
                  readOnly
                />
              </div>

            </div>
            <FormInput
              className='w-full'
              label="Email"
              value={profileData.profile?.email}
              readOnly
            />
            <FormInput
              className='w-full'
              label="Phone"
              prefix='+1'
              value={profileData.profile?.phone}
              readOnly
            />
          </div>
        </div>
      </div>
      <WeeklyRegularForm clientTasks={profileData.client.tasks} week={profileData.client.week}/>
    </div>
  );
};

