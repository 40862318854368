import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconLogout({ onClick, className, color = '#000' })  {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6 group', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path className='duration-150 group-hover:stroke-orange' d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9M16 17L21 12M21 12L16 7M21 12H9" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    </svg>
  )
}
