import React, { useMemo } from 'react';
import { OrangeButton } from 'components';
import { useNavigate } from 'react-router-dom';
import { formatPriceWithComma } from 'utils/helpers';

export const ActivePartnersSLH = ({ partners }) => {
  const navigate = useNavigate();
  partners.sort((a, b) => b.successful_clients - a.successful_clients);
  partners = partners.slice(0, 3);

  const storeACtivePartners = useMemo(() => {
    return partners.map((partner, index) => {
      const totalClients = partner.successful_clients + partner.failed_clients;
      let successRate = partner.successful_clients === 0 ? 0 : (partner.successful_clients / totalClients) * 100;
      successRate = Number.isInteger(successRate) ? successRate : successRate.toFixed(2);

      return (
        <div className={`flex py-6 mx-[30px] ${index + 1 !== partners.length ? 'border-b border-divider' : ''}`} key={`${index}`}>
          <p className='w-[55%] text-14 font-medium text-black capitalize'>{partner.name}</p>
          <p className='w-[20%] text-14 font-medium text-black capitalize'>{totalClients}</p>
          <p className={`w-[15%] text-14 font-medium capitalize ${successRate >= 0 ? 'text-green' : 'text-red'}`}>{successRate}%</p>
          <p className='w-[10%] text-14 font-medium text-black capitalize text-right'>${formatPriceWithComma(partner.expences)}</p>
        </div>
      )
    })
  }, [partners])

  return (
    <div className='flex flex-col pt-[30px] base-block w-full pb-1.5'>
      <div className='flex justify-between items-center mb-8 px-[30px]'>
        <p className='nunito-title'>Most Active Partners - Sober Living Homes</p>
        <OrangeButton type='button' label='See All' onClick={() => navigate('/admin/partners')} />
      </div>
      <div className='flex flex-col'>
        <div className='flex pb-4 border-b border-divider px-[30px]'>
          <p className='w-[55%] text-14 text-lightBlue capitalize'>Name</p>
          <p className='w-[20%] text-14 text-lightBlue capitalize'>Clients</p>
          <p className='w-[15%] text-14 text-lightBlue capitalize'>Success Rate</p>
          <p className='w-[10%] text-14 text-lightBlue capitalize text-right'>Total Paid</p>
        </div>
        {storeACtivePartners}
      </div>
    </div>
  )
};

