import React, { useState } from 'react';
import classNames from 'classnames';
import { IconClients, IconSoberLiving, IconWallet, InvoiceHistory, SmallButton, SuccessFailRate } from 'components';
import { formatPriceWithComma } from 'utils/helpers';
import { COLORS } from 'utils/colors';
import { ROLE_TYPES } from 'api/constants';

export const FinancesSinglePartner = ({ ...props }) => {
  const { gender, name, address, rank, id } = props;
  const [isModalVisible, setIsModalVisible] = useState(false);
  
  return (
    <div className='flex flex-col relative base-block w-[372px] h-full rounded-block-radius-sm'>
      <div className={classNames('flex flex-col flex-1 p-4 pb-3 border-b border-color-divider relative gap-y-1 min-h-[101px]',
      )}>
        <SmallButton
          onClick={() => setIsModalVisible(true)}
          className='absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2'
          label='View invoice'
        />
        {isModalVisible && <InvoiceHistory
          isOpen={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          partnerId={id}
          role={ROLE_TYPES.admin}
          partnerName={name}
          partnerAddress={address}
        />}
        <div className='flex items-center gap-x-3'>
          <IconSoberLiving color={COLORS.blue} />
          <p className='nunito-title text-base leading-6 line-clamp-2	flex-1'>{name}</p>
        </div>
        <p className='text-14 text-lightBlue text-ellipsis whitespace-nowrap pr-6 overflow-hidden'>{address}</p>
        <div className='flex gap-x-1'>
          {gender?.split(',').map((gender, index) => {
            return (
              <span key={`${gender}-${index}`} className='text-blue uppercase text-14'>{gender}</span>
            )
          })}
        </div>
        <span className='absolute right-0 bottom-3 px-3.5 py-2.5 text-14 font-medium border border-divider bg-light rounded-l-[20px]'>{rank}</span>
      </div>
      <div className='flex items-stretch justify-center gap-x-7 p-4 pt-6'>
        <div className='flex flex-col items-center'>
          <div className='bg-lightGreen rounded-full w-10 h-10 flex items-center justify-center'>
            <IconClients className='w-5 h-5' color={COLORS.green} />
          </div>
          <div className='flex flex-col items-center'>
            <p className='text-14 text-lightBlue mt-1 mb-2 whitespace-nowrap'>Active Clients</p>
            <div className='flex items-center gap-x-1'>
              <p className='text-14 font-semibold'>24</p>
              <SuccessFailRate rate={2}/>
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center'>
          <div className='bg-lightGreen rounded-full w-10 h-10 flex items-center justify-center'>
            <IconWallet className='w-5 h-5' color={COLORS.green} />
          </div>
          <div className='flex flex-col items-center'>
            <p className='text-14 text-lightBlue mt-1 mb-2 whitespace-nowrap'>Week to day</p>
            <p className='text-14 font-semibold'>${formatPriceWithComma(10820)}</p>
          </div>
        </div>
        <div className='flex flex-col items-center'>
          <div className='bg-lightGreen rounded-full w-10 h-10 flex items-center justify-center'>
            <IconWallet className='w-5 h-5' color={COLORS.green} />
          </div>
          <div className='flex flex-col items-center'>
            <p className='text-14 text-lightBlue mt-1 mb-2 whitespace-nowrap'>Week forecast</p>
            <p className='text-14 font-semibold'>${formatPriceWithComma(12000)}</p>
          </div>
        </div>
      </div>
    </div>
  )
};

