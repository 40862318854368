import { ROLE_TYPES } from 'api/constants';
import { FinancesSummary, FinancesPartners, FinancesClients } from 'components';
import React from 'react';

export const AdminFinances = () => {

  return (
    <div className="flex flex-col w-full">
      <p className='nunito-title uppercase mb-6'>Finances</p>
      <FinancesSummary />
      <FinancesPartners domain={ROLE_TYPES.admin} />
      <FinancesClients domain={ROLE_TYPES.admin} />
    </div>
  );
}

