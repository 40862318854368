import React from 'react';
import { Tooltip } from 'antd';
import { COLORS } from 'utils/colors';
import { IconInfo } from 'components/icons/Info';

export const TooltipIcon = ({ tooltipText, placement = 'bottom' }) => {

  return (
    <Tooltip
      className='select-none ml-1 text-[0px]'
      overlayInnerStyle={{
        padding: '12px 16px',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'poppins',
        backgroundColor: "#000",
      }}
      placement={placement}
      title={tooltipText}>
      <IconInfo className='w-4 h-4' color={COLORS.blue} />
      tooltip
    </Tooltip>
  )
}