import React from 'react'
import { twMerge } from 'tailwind-merge';

export function IconBed({ onClick, className, color = '#FDB513' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-5 h-5 group', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path className='group-hover:stroke-orange' d="M2.33319 4.5V20.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path className='group-hover:stroke-orange' d="M2.33319 8.5H20.3332C20.8636 8.5 21.3723 8.71071 21.7474 9.08579C22.1225 9.46086 22.3332 9.96957 22.3332 10.5V20.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path className='group-hover:stroke-orange' d="M2.33319 17.5H22.3332" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path className='group-hover:stroke-orange' d="M6.33337 8.5V17.5" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  )
}
