import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Radio, } from 'antd';
import { FormInput, IconSmallArrow, OrangeButton, TooltipIcon, } from 'components';
import classNames from 'classnames';
import { getRequestData, updatePartner } from 'api/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { endpoints } from 'api/endpoints';
import { getIdFromPath } from 'utils/helpers';
import { ROLE_TYPES } from 'api/constants';

const defaultFormData = {
  slh: {
    name: "",
    region: "",
    rank: "",
    gender: "",
    parent: "",
    bed_count: null,
    address: "",
    website: "",
    weekly_rate: null,
  },
  manager: {
    firstname: "",
    email: "",
    phone: null
  },
  supervisor: {
    name: "",
    phone: null,
    email: "",
  },
};

export const EditPartner = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [formData, setFormData] = useState(defaultFormData);
  const [form] = Form.useForm();
  const userID = getIdFromPath(location.pathname);

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getPartner(userID), ROLE_TYPES.admin);

      if (data) {
        const {
          name,
          region,
          rank,
          gender,
          parent,
          bed_count,
          address,
          website,
          weekly_rate,
          manager,
          supervisor_name,
          supervisor_phone,
          supervisor_email
        } = data;
        const updatedFormData = {
          slh: {
            ...defaultFormData.slh,
            name,
            region,
            parent,
            bed_count,
            address,
            website,
            weekly_rate,
            gender: gender.split(","),
            rank: String(rank),
          },
          manager: {
            ...defaultFormData.manager,
            firstname: manager.firstname,
            phone: manager.phone,
            email: manager.email,
          },
          supervisor: {
            ...defaultFormData.supervisor,
            name: supervisor_name,
            phone: supervisor_phone,
            email: supervisor_email,
          },
        };
        setFormData(updatedFormData);
        form.setFieldsValue(updatedFormData);
      }
    })();
  }, [form, location.pathname, userID])

  useEffect(() => {
    const isFormValid =
      !!formData.slh.name &&
      !!formData.slh.bed_count &&
      !!formData.slh.weekly_rate &&
      !!formData.slh.gender.length > 0 &&
      !!formData.slh.rank &&
      !!formData.supervisor.name &&
      !!formData.supervisor.email;
    setIsBtnDisabled(!isFormValid);
  }, [formData]);

  const handleGenderChange = (genders) => {
    const selectedGenders = genders.join(',');

    setFormData({
      ...formData,
      slh: {
        ...formData.slh,
        gender: selectedGenders,
      }
    });
  }

  const handleRankChange = (e) => {
    const selectedRank = e.target.value;

    setFormData({
      ...formData,
      slh: {
        ...formData.slh,
        rank: selectedRank,
      }
    });
  }

  const onValuesChange = (changedFields, allValues) => {
    setFormData(allValues);
  }
  const handleFormSubmit = (values) => {
    values.slh.gender = values.slh.gender.join(',');

    updatePartner(values, userID, () => {
      navigate('/admin/partners');
    });
  }

  return (
    <Form
      form={form}
      name='create-client-by-admin'
      initialValues={formData}
      className='flex flex-col w-full gap-6'
      onValuesChange={onValuesChange}
      onFinish={handleFormSubmit}
      requiredMark={false}
    >
      <div className='flex justify-between w-full'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate('/admin/partners')} className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
          <p className='nunito-title'>Edit Partner (SLH)</p>
        </div>
        <OrangeButton type='submit' label='Save' disabled={isBtnDisabled} />
      </div>
      <div className='flex flex-wrap items-start gap-6'>
        <div className='flex flex-col w-full base-block pt-[30px]'>
          <p className='nunito-title px-[30px] pb-[11px]'>SLH</p>
          <div className={classNames('flex flex-wrap relative border-t border-color-lightBlue p-[30px] pt-3 gap-x-8 gap-y-6',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',)}
          >
            <FormInput
              label="Name*"
              name={['slh', 'name']}
              placeholder="First Name"
              required
              errorMessage='Please enter valid name'
              className='w-[calc(50%-16px)]'
              value={formData.slh.name}
            />
            <FormInput
              label="Region"
              name={['slh', 'region']}
              placeholder="Enter region"
              className='w-[calc(50%-16px)]'
              value={formData.slh.region}
            />
            <div className='flex flex-col gap-y-2 w-[calc(50%-16px)]'>
              <div className='flex justify-between items-center'>
                <p className='text-14 font-medium'>Classification (Ranking)*</p>
                <TooltipIcon tooltipText='Tooltip about rank' />
              </div>
              <Form.Item name={['slh', 'rank']}>
                <Radio.Group onChange={handleRankChange} className='w-full flex gap-x-4' value={'1'}>
                  <Radio className='px-4 py-2.5 m-0 bg-white border border-color-orange rounded-xl flex-1' value={'1'}>1</Radio>
                  <Radio className='px-4 py-2.5 m-0 bg-white border !border-color-orange rounded-xl flex-1' value={'2'}>2</Radio>
                  <Radio className='px-4 py-2.5 m-0 bg-white border !border-color-orange rounded-xl flex-1' value={'3'}>3</Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <FormInput
              label="Address"
              name={['slh', 'address']}
              placeholder="Enter address"
              className='w-[calc(50%-16px)]'
            />
            <div className='flex flex-col gap-y-2 w-[calc(50%-16px)]'>
              <p className='text-14 font-medium'>Gender*</p>
              <Form.Item name={['slh', 'gender']} required>
                <Checkbox.Group onChange={handleGenderChange} className='w-full gap-x-4'>
                  <Checkbox className='px-4 py-2.5 bg-white border font-medium rounded-xl gap-x-2 flex-1' value={'M'}>Male</Checkbox>
                  <Checkbox className='px-4 py-2.5 bg-white border font-medium rounded-xl gap-x-2 flex-1' value={'F'}>Female</Checkbox>
                  <Checkbox className='px-4 py-2.5 bg-white border font-medium rounded-xl gap-x-2 flex-1' value={'O'}>Other</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </div>
            <FormInput
              label="Website"
              name={['slh', 'website']}
              placeholder="Enter website"
              className='w-[calc(50%-16px)]'
            />
            <div className='flex gap-x-6 w-[calc(50%-16px)]'>
              <FormInput
                className='w-full'
                label="Parent"
                name={['slh', 'parent']}
                placeholder="Enter parent"
                required
              />
              <FormInput
                className='w-full'
                label="Bed Count*"
                type='number'
                name={['slh', 'bed_count']}
                placeholder="Enter total beds count"
                required
              />
            </div>
            <FormInput
              label="Weekly Rate*"
              type='number'
              name={['slh', 'weekly_rate']}
              placeholder="$"
              required
              className='w-[calc(50%-16px)]'
            />
          </div>
        </div>
        <div className='flex flex-col w-[calc(50%-12px)] base-block pt-[30px]'>
          <p className='nunito-title px-[30px] pb-[11px] uppercase'>SLH Manager</p>
          <div className={classNames('flex flex-wrap relative border-t border-color-lightBlue p-[30px] pt-3 gap-x-8 gap-y-6',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',)}
          >
            <FormInput
              label="Name*"
              name={['manager', 'firstname']}
              placeholder="Enter manager's name"
              required
              className='w-full'
              inputClassName='cursor-default'
              readOnly
              disabled
            />
            <FormInput
              className='w-full'
              inputClassName='[&>*]:cursor-default'
              label="Phone"
              type='number'
              name={['manager', 'phone']}
              placeholder="Phone"
              prefix='+1'
              readOnly
              disabled
            />
            <FormInput
              type='email'
              className='w-full'
              inputClassName='cursor-default'
              readOnly
              label={
                <>
                  <p className='text-14 font-medium'>Email*</p>
                  <TooltipIcon tooltipText='This email will be used as SLH Managed Login credential' />
                </>
              }
              name={['manager', 'email']}
              placeholder="Email"
              disabled
            />
          </div>
        </div>
        <div className='flex flex-col w-[calc(50%-12px)] base-block pt-[30px]'>
          <p className='nunito-title px-[30px] pb-[11px] uppercase'>SLH Superviser</p>
          <div className={classNames('flex flex-wrap relative border-t border-color-lightBlue p-[30px] pt-3 gap-x-8 gap-y-6',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',)}
          >
            <FormInput
              label="Name*"
              name={['supervisor', 'name']}
              placeholder="Enter supervisor's name"
              required
              className='w-full'
              errorMessage='Please enter valid name'
            />
            <FormInput
              className='w-full'
              label="Phone"
              name={['supervisor', 'phone']}
              type='number'
              placeholder="Phone"
              errorMessage='Please enter valid phone'
              prefix='+1'
            />
            <FormInput
              className='w-full'
              label="Email*"
              name={['supervisor', 'email']}
              placeholder="Email"
              required
              errorMessage='Please enter valid email'
            />
          </div>
        </div>
      </div>
    </Form>
  );
};
