import React from 'react';
import { Tooltip } from 'antd';

export const TooltipButton = ({ icon, text, tooltipText,placement='bottom' }) => {

  const childWithHover = icon && React.cloneElement(icon, { className: 'group-hover:[&>path]:fill-white [&>path]:duration-150' });

  return (
    <Tooltip 
      className='select-none rounded-lg py-1 px-3 flex items-center bg-light text-14 text-blue font-medium gap-x-1.5 duration-150 group hover:bg-blue hover:text-white'
      placement={placement}
      overlayInnerStyle={{
        padding: '12px 16px',
        borderRadius: '8px',
        fontSize: '14px',
        lineHeight: '16px',
        fontFamily: 'poppins',
        backgroundColor: "#000",
      }}
      title={tooltipText}>
      {icon && childWithHover}
      {text}
    </Tooltip>
  )
}