import React from 'react';
import { Form, Input } from 'antd';

export const FormInput = ({ label, name, className, type, placeholder, errorMessage, required, value, defaultValue, inputClassName, visibilityToggle = true, ...restProps }) => {
  return (
    <Form.Item
      label={label}
      name={name}
      layout="vertical"
      className={`font-poppins ${className ? className : ''}`}
      rules={[
        {
          required: required,
          message: errorMessage || 'This field is required',
        },
      ]}
    >
      {type === 'password' ?
        <Input.Password {...restProps} placeholder={placeholder} value={value} visibilityToggle={visibilityToggle} /> :
        <Input {...restProps} className={`${restProps.prefix ? 'with-prefix' : ''} ${inputClassName ? inputClassName : ''}`} placeholder={placeholder} type={type} value={value} defaultValue={defaultValue} />
      }
    </Form.Item>
  );
};
