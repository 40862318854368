import React, { useState } from 'react';
import { useAuth } from 'context/authContext';
import { useNavigate } from "react-router-dom";
import { ForgotPassword, FormInput, NotificationBlock, OrangeButton, SmallButton } from 'components';
import axios from 'axios';
import { Form } from 'antd';
import Logo from 'assets/images/logo.svg';
import classNames from 'classnames';
import { endpoints } from 'api/endpoints';
import { toast } from 'react-toastify';
import { ROLE_TYPES } from 'api/constants';

export const ClientLoginForm = () => {
  const navigate = useNavigate();
  const { login, roleSetter } = useAuth();
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [isForgetPassword, setIsForgetPassword] = useState(false);

  const onFinish = (values) => {
    axios.post(endpoints.clientLogin, values)
      .then(async (response) => {
        if (response.status === 200) {
          const userRole = response.data.user.role;
          const token = response.data.token;

          login(token, userRole);
          roleSetter(userRole);
          navigate('/client/dashboard');
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const onValuesChange = (_, allValues) => {
    const email = allValues.email;
    const password = allValues.password;
    setBtnDisabled(!(email !== undefined && email !== '' && password !== undefined && password !== ''));
  };

  return isForgetPassword ? <ForgotPassword domain={ROLE_TYPES.client} showLogin={() => setIsForgetPassword(false)} /> : (
    <div className='flex flex-col bg-white rounded-[60px] pt-10 pb-12 w-[40rem] h-[calc(100%-64px)] min-h-[46rem] my-8'>
      <img className='w-48 mb-6 self-center' src={Logo} alt='pw25 logo' />
      <div className={classNames('flex-1 relative w-full flex flex-col py-8 border-b border-t border-color-divider px-20 self-center items-center',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
        'after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-color-divider after:bg-white after:z-10 after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:translate-y-1/2',
      )}>
        <p className='mb-4 nunito-title'>Welcome</p>
        <p className='text-lightBlue text-base leading-[24px]'>Please login into your account</p>
        <Form
          className='mt-16 flex flex-col w-full gap-y-6'
          name="auth"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
          autoComplete="off"
          requiredMark={'optional'}
          clearOnDestroy={false}
        >
          <FormInput
            label="Login"
            name="email"
            placeholder="Email"
            required
            errorMessage='Please enter valid email'
          />
          <FormInput
            type='password'
            label="Password"
            name="password"
            placeholder="Password"
            required
            errorMessage='Please enter correct password'
          />
          <OrangeButton type='submit' className='self-center' label='Login' disabled={btnDisabled} />
        </Form>
      </div>
      <SmallButton className='self-center !mt-7' label='Forgot password? ' onClick={() => setIsForgetPassword(true)} />
    </div>
  )
}
