import React from 'react';
import { IconApproximately, IconClients, IconWallet, OrangeButton, SuccessFailRate } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import { formatPriceWithComma } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';

export const ScholarshipsAnalytics = ({ scholarships, detailsUrl }) => {
    const navigate = useNavigate();

    return (
        <div className='flex flex-col px-[30px] pt-[30px] base-block w-full'>
            <div className='flex justify-between items-center mb-8'>
                <p className='nunito-title'>Scholarships</p>
                <div className='flex items-center base-block-light-shadow p-1 pl-6 mr-6 ml-auto hidden'>
                    <p className='text-14 text-lightBlue mr-3'>YTD Granted</p>
                    <div className='flex items-center bg-lightGreen py-1.5 pr-6 pl-3 rounded-md'>
                        <IconWallet color={COLORS.green} />
                        <p className='text-14 font-bold ml-2'>$850,600</p>
                    </div>
                    <IconApproximately className='w-4 mx-3' />
                    <div className='flex items-center bg-yellow py-1.5 pr-6 pl-3 rounded-md'>
                        <IconClients color={COLORS.orange} />
                        <p className='text-14 font-bold ml-2'>1700</p>
                    </div>
                </div>
                <OrangeButton onClick={() => navigate(detailsUrl)} type='button' label='Details' />
            </div>
            <div className='flex border-t border-divider'>
                <div className='flex items-start justify-center flex-1 pb-[30px] pt-4'>
                    <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
                        <IconWallet className='w-10 h-10' color={COLORS.green} />
                    </div>
                    <div className='flex flex-col ml-5'>
                        <p className='text-14 text-lightBlue'>YTD Granted</p>
                        <p className='text-24 text-black my-1'>${formatPriceWithComma(scholarships.total_granted)}</p>
                        <div className='flex items-center'>
                            <IconClients className='w-4 h-4'/>
                            <p className='text-12 text-lightBlue ml-0.5 mr-1 font-normal'>Helped:</p>
                            <p className='text-12 text-blue'>{scholarships.helped_peoples}</p>
                        </div>
                    </div>
                </div>
                <div className={classNames('flex items-start justify-center flex-1 pb-[30px] pt-4 relative border-r border-l border-divider',
                    'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-1/2 before:left-0 before:-translate-x-1/2 before:-translate-y-1/2',
                    'after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-color-lightBlue after:bg-white after:z-10 after:top-1/2 after:right-0 after:translate-x-1/2 after:-translate-y-1/2',)}>
                    <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
                        <IconWallet className='w-10 h-10' color={COLORS.green} />
                    </div>
                    <div className='flex flex-col ml-5'>
                        <p className='text-14 text-lightBlue'>Month's Obligation</p>
                        <p className='text-24 text-black my-1'>${formatPriceWithComma(scholarships.month.forecast)}</p>
                        <SuccessFailRate rate={scholarships.month.mom}/>
                    </div>
                </div>
                <div className='flex items-start justify-center flex-1 pb-[30px] pt-4'>
                    <div className='bg-lightGreen rounded-full w-[84px] h-[84px] flex items-center justify-center'>
                        <IconWallet className='w-10 h-10' color={COLORS.green} />
                    </div>
                    <div className='flex flex-col ml-5'>
                        <p className='text-14 text-lightBlue'>Week's Obligation</p>
                        <p className='text-24 text-black my-1'>${formatPriceWithComma(scholarships.week.forecast)}</p>
                        <SuccessFailRate rate={scholarships.week.wow}/>
                    </div>
                </div>
            </div>
        </div>
    )
};

