import React, { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from "react-router-dom";
import { IconDashboard, IconClients, IconWallet, IconSoberLiving, IconLogout } from "components";
import classNames from 'classnames';
import { useAuth } from 'context/authContext';

import Logo from 'assets/images/logo.svg';
import DefaultAvatar from 'assets/images/default-avatar.png';
import { isInRoute } from 'utils/helpers';
import { ROLE_TYPES } from 'api/constants';
import { useUser } from 'context/userContext';
import { endpoints } from 'api/endpoints';
import { getRequestData } from 'api/api';

export const SideMenu = () => {
  const { role, logout } = useAuth();
  const { userDetails } = useUser();
  const location = useLocation();
  const navigate = useNavigate();
  const [slhInfo, setSlhInfo] = useState();

  useEffect(() => {
    if (role === ROLE_TYPES.slh_manager && userDetails?.partner_id) {
      (async () => {
        const { data } = await getRequestData(endpoints.getPartner(userDetails?.partner_id), ROLE_TYPES.slh_manager);
        if (data) setSlhInfo(data);
      })()
    }
  }, [role, userDetails?.partner_id])

  return (
    <div className='flex flex-col base-block w-full max-w-[306px] h-[calc(100vh-80px)] sticky top-10'>
      <img onClick={() => navigate(role === ROLE_TYPES.admin ? '/admin/dashboard' : '/manager/dashboard')} className='w-48 mb-6 pt-7 ml-7 cursor-pointer' src={Logo} alt='pw25 logo' />
      {role === ROLE_TYPES.slh_manager && slhInfo && (
        <div className='flex items-center bg-light p-2 w-[calc(100%-40px)] mx-auto mb-5 gap-x-2 rounded-r-[10px] rounded-l-[35px]'>
          <IconSoberLiving />
          <div className='flex flex-col flex-1'>
            <b className='text-14 mb-0.5'>{slhInfo.name}</b>
            <p className='text-12 font-normal text-lightBlue'>{slhInfo.address}</p>
          </div>
        </div>
      )}
      <nav className={classNames('flex flex-1 relative border-b border-t border-divider',
        'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-divider before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
        'after:content-[""] after:absolute after:w-4 after:h-4 after:rounded-full after:border after:border-divider after:bg-white after:z-10 after:bottom-0 after:left-1/2 after:-translate-x-1/2 after:translate-y-1/2',
      )}>
        <div className='flex flex-col gap-y-4 w-full overflow-hidden mt-10'>
          <Link className='flex items-center py-3 pr-3 duration-150 group hover:bg-yellow' to={`${role === ROLE_TYPES.admin ? '/admin' : '/manager'}/dashboard`}>
            <span className={`w-6 h-6 rounded-full border border-lightBlue -translate-x-1/2 group-hover:border-black group-hover:bg-black ${location.pathname.includes('dashboard') ? 'bg-orange border-orange' : 'bg-transparent'}`} />
            <IconDashboard className='mr-3 duration-150 group-hover:[&_path]:stroke-black [&_path]:duration-150' color={isInRoute(location.pathname, 'dashboard') ? 'orange' : 'black'} />
            <p className={`text-sm leading-[21px] duration-150 group-hover:text-black ${isInRoute(location.pathname, 'dashboard') ? 'text-orange' : 'text-black'}`}>Dashboard</p>
          </Link>
          <Link className='flex items-center py-3 pr-3 duration-150 group hover:bg-yellow' to={`${role === ROLE_TYPES.admin ? '/admin' : '/manager'}/clients`}>
            <span className={`w-6 h-6 rounded-full border border-lightBlue -translate-x-1/2 group-hover:border-black group-hover:bg-black ${isInRoute(location.pathname, 'client') ? 'bg-orange border-orange' : 'bg-transparent'}`} />
            <IconClients className='mr-3 group-hover:[&_path]:stroke-black [&_path]:duration-150' color={isInRoute(location.pathname, 'client') ? 'orange' : 'black'} />
            <p className={`text-sm leading-[21px] duration-150 group-hover:text-black ${isInRoute(location.pathname, 'client') ? 'text-orange' : 'text-black'}`}>{role === ROLE_TYPES.admin ? "Clients" : "PF25 Clients"}</p>
          </Link>
          {role === ROLE_TYPES.admin && <Link className='flex items-center py-3 pr-3 duration-150 group hover:bg-yellow' to='/admin/partners'>
            <span className={`w-6 h-6 rounded-full border border-lightBlue -translate-x-1/2 group-hover:border-black group-hover:bg-black ${isInRoute(location.pathname, 'partner') ? 'bg-orange border-orange' : 'bg-transparent'}`} />
            <IconSoberLiving className='mr-3 group-hover:[&_path]:stroke-black [&_path]:duration-150' color={isInRoute(location.pathname, 'partner') ? 'orange' : 'black'} />
            <p className={`text-sm leading-[21px] duration-150 group-hover:text-black ${isInRoute(location.pathname, 'partner') ? 'text-orange' : 'text-black'}`}>Partners (SLH)</p>
          </Link>}
          <Link className='flex items-center py-3 pr-3 duration-150 group hover:bg-yellow' to={`${role === ROLE_TYPES.admin ? '/admin' : '/manager'}/finances`}>
            <span className={`w-6 h-6 rounded-full border border-lightBlue -translate-x-1/2 group-hover:border-black group-hover:bg-black ${isInRoute(location.pathname, 'finance') ? 'bg-orange border-orange' : 'bg-transparent'}`} />
            <IconWallet className='mr-3 group-hover:[&_path]:stroke-black [&_path]:duration-150' color={isInRoute(location.pathname, 'finance') ? 'orange' : 'black'} />
            <p className={`text-sm leading-[21px] duration-150 group-hover:text-black ${isInRoute(location.pathname, 'finance') ? 'text-orange' : 'text-black'}`}>Finances</p>
          </Link>
        </div>
      </nav>
      <div className='flex justify-between items-center pt-10 pb-8 px-7'>
        <div className='flex'>
          <img className='w-[42px] h-[42px] rounded-full' src={userDetails?.avatar ? userDetails.avatar : DefaultAvatar} alt='profile' />
          <div className='flex flex-col ml-3'>
            <p className='text-black text-sm'>{userDetails?.firstname} {userDetails?.lastname}</p>
            <p className='text-lightBlue text-xs font-normal capitalize'>{role.replace('_', ' ')}</p>
          </div>
        </div>
        <IconLogout className='cursor-pointer' onClick={logout} />
      </div>
    </div>
  );
};
