import React, { createContext, useState, useContext, useMemo } from 'react';
import { ACCESS_TOKEN_NAME, PARTNER_ID, ROLE_TYPES, USER_ROLE } from 'api/constants';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();

  const userRole = sessionStorage.getItem(USER_ROLE);
  const isToken = localStorage.getItem(ACCESS_TOKEN_NAME[userRole]);
  const partnerID = localStorage.getItem(PARTNER_ID);

  const [auth, setAuth] = useState(!!isToken);
  const [role, setRole] = useState(userRole);
  const [partnerId, setPartnerId] = useState(partnerID);

  const login = (token, role) => {
    localStorage.setItem(ACCESS_TOKEN_NAME[role], token);
    setAuth(true);
  };

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN_NAME[role]);
    sessionStorage.removeItem(USER_ROLE);
    if (role === ROLE_TYPES.slh_manager) {
      localStorage.removeItem(PARTNER_ID);
    }
    setAuth(false);
    setRole(null);
    if (partnerID) setPartnerId(null);

    if (role === ROLE_TYPES.admin || role === ROLE_TYPES.slh_manager) {
      return navigate("/")
    }
    return navigate("/login")
  }

  const roleSetter = (role) => {
    sessionStorage.setItem(USER_ROLE, role);
    setRole(role);
  }

  const partnerIDSetter = (partner_id) => {
    localStorage.setItem(PARTNER_ID, partner_id);
    setPartnerId(partner_id);
  }

  const value = useMemo(() => ({
    auth, login, logout, role, roleSetter, partnerIDSetter, partnerId
  }), [auth, partnerId, role]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
