import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconDashboard({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.318 13.5H15.443C14.9295 13.5 14.4369 13.7034 14.073 14.0656C13.709 14.4279 13.5034 14.9195 13.501 15.433V19.308C13.501 19.8231 13.7056 20.317 14.0698 20.6812C14.434 21.0454 14.9279 21.25 15.443 21.25H19.318C19.8313 21.2474 20.3227 21.0416 20.6847 20.6777C21.0468 20.3138 21.25 19.8213 21.25 19.308V15.433C21.25 14.9206 21.0464 14.4292 20.6841 14.0669C20.3218 13.7045 19.8304 13.501 19.318 13.501M8.557 13.5H4.682C4.16859 13.5029 3.6772 13.7089 3.3152 14.073C2.95319 14.437 2.74999 14.9296 2.75 15.443V19.318C2.75 19.8304 2.95355 20.3218 3.31587 20.6841C3.67819 21.0464 4.1696 21.25 4.682 21.25H8.557C9.07032 21.25 9.56277 21.0468 9.92668 20.6847C10.2906 20.3227 10.4964 19.8313 10.499 19.318V15.443C10.499 14.9279 10.2944 14.434 9.9302 14.0698C9.56601 13.7056 9.07205 13.501 8.557 13.501M8.557 2.75H4.682C4.42829 2.75 4.17706 2.79997 3.94266 2.89706C3.70825 2.99416 3.49527 3.13647 3.31587 3.31587C3.13647 3.49527 2.99416 3.70825 2.89706 3.94266C2.79997 4.17706 2.75 4.42829 2.75 4.682V8.557C2.74999 9.07032 2.95322 9.56277 3.31526 9.92668C3.67729 10.2906 4.16868 10.4964 4.682 10.499H8.557C9.07205 10.499 9.56601 10.2944 9.9302 9.9302C10.2944 9.56601 10.499 9.07205 10.499 8.557V4.682C10.4964 4.16868 10.2906 3.67729 9.92668 3.31526C9.56277 2.95322 9.07032 2.74999 8.557 2.75ZM19.318 2.75H15.443C14.9297 2.74999 14.4372 2.95322 14.0733 3.31526C13.7094 3.67729 13.5036 4.16868 13.501 4.682V8.557C13.5013 9.07197 13.706 9.56577 14.0701 9.92991C14.4342 10.294 14.928 10.4987 15.443 10.499H19.318C19.8313 10.4964 20.3227 10.2906 20.6847 9.92668C21.0468 9.56277 21.25 9.07032 21.25 8.557V4.682C21.25 4.1696 21.0464 3.67819 20.6841 3.31587C20.3218 2.95355 19.8304 2.75 19.318 2.75Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  )
}
