import React, { useState } from 'react';
import { InvoiceHistory, OrangeButton } from 'components';
import { FinancesSummary, FinancesClients } from 'components';
import { ROLE_TYPES } from 'api/constants';
import { useAuth } from 'context/authContext';

export const ManagerFinances = () => {
  const { partnerId } = useAuth();
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <div className="flex flex-col w-full gap-y-6">
      <div className='flex justify-between'>
        <p className='nunito-title uppercase'>Finances</p>
        <OrangeButton label='View invoice' onClick={() => setIsModalVisible(true)} />
        {isModalVisible && <InvoiceHistory
          isOpen={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          partnerId={partnerId}
          role={ROLE_TYPES.slh_manager}
        />}
      </div>
      <FinancesSummary isManager />
      <FinancesClients domain={ROLE_TYPES.slh_manager} />
    </div>
  );
}
