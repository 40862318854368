import React from 'react';
import AnimateHeight from 'react-animate-height';

export const CustomCollapse = ({ isOpen, className, children }) => {
  return (
    <AnimateHeight
      className={className}
      duration={100}
      height={isOpen ? 'auto' : 0}
    >
      {children}
    </AnimateHeight>
  );
};
