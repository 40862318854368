import React, { useEffect } from 'react';
import { useAuth } from 'context/authContext';
import { useNavigate } from "react-router-dom";
import { ClientLoginForm } from 'components';
import Skates from 'assets/images/skates.png';

export const ClientLoginPage = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth) {
      return navigate("/");
    }
  }, [auth, navigate])

  return !auth && (
    <div className='flex relative justify-end h-full overflow-y-auto overflow-x-hidden bg-orange min-h-screen'>
      <div className='absolute z-10 top-0 bottom-0 left-[31%] -translate-x-1/2'>
        <ClientLoginForm />
      </div>
      <div className='w-[69%] min-h-[52rem] relative'>
        <img className='absolute left-0 bottom-0 right-0 w-full h-full object-cover' src={Skates} alt='hero' />
      </div>
    </div>
  );
};

