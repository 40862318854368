import React, { useEffect, useState } from 'react';
import { Radio } from 'antd';
import { FormInput, IconSmallArrow, IconPaperclip, SmallButton, IconEye } from 'components';
import classNames from 'classnames';
import { COLORS } from 'utils/colors';
import { useNavigate } from 'react-router-dom';
import { GENDER, ROLE_TYPES } from 'api/constants';
import { endpoints } from 'api/endpoints';
import { getRequestData } from 'api/api';
import dayjs from 'dayjs';
import DefaultAvatar from 'assets/images/default-avatar.png';

export const ClientProfile = () => {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.clientProfile, ROLE_TYPES.client);
      if (data) setProfileData(data);
    })();

  }, [])

  return profileData && (
    <div
      className='flex justify-center w-full relative'
    >
      <div className='flex justify-between absolute top-[52px] left-[calc(50%-245px)] -translate-x-full'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate(-1)} className='w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
        </div>
      </div>
      <div className='flex gap-6'>
        <div className='flex flex-col gap-[18px] base-block pt-[30px] w-[442px]'>
          <p className='nunito-title px-[30px] pb-[11px]'>Personal Information</p>
          <div className={classNames('relative w-full flex flex-col pt-14 pb-[30px] gap-y-[18px] border-t border-color-lightBlue px-[30px] self-center items-center',
            'before:content-[""] before:absolute before:w-4 before:h-4 before:rounded-full before:border before:border-color-lightBlue before:bg-white before:z-10 before:top-0 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2',
          )}>
            <img className='self-center w-[190px] h-[190px] rounded-xl' src={profileData.profile.avatar ? profileData.profile.avatar : DefaultAvatar} alt='profile' />
            <FormInput
              label="Full Name"
              className='w-full'
              value={`${profileData.profile.firstname} ${profileData.profile.lastname}`}
              readOnly
            />
            <div className='flex flex-col gap-y-1 w-full'>
              <p className='text-14 font-medium'>Date Of Birth</p>
              <div className='flex gap-x-3'>
                <FormInput
                  inputClassName='text-center text-base'
                  value={new Date(profileData.profile.birthdate).getDate()}
                  readOnly
                />
                <FormInput
                  inputClassName='text-center text-base'
                  value={dayjs().month(new Date(profileData.profile.birthdate).getMonth()).format('MMMM')}
                  readOnly
                />
                <FormInput
                  inputClassName='text-center text-base'
                  value={new Date(profileData.profile.birthdate).getFullYear()}
                  readOnly
                />
              </div>

            </div>
            <FormInput
              className='w-full'
              label="Email"
              value={profileData.profile.email}
              readOnly
            />
            <FormInput
              className='w-full'
              label="Phone"
              prefix='+1'
              value={profileData.profile.phone}
              readOnly
            />
            <div className='flex justify-between w-full p-4 rounded-lg shadow-block-shadow'>
              <div className='flex items-center gap-x-1'>
                <IconPaperclip color={COLORS.black} />
                <p className='text-16'>Attached Document</p>
              </div>
              <SmallButton icon={<IconEye className={!profileData.client.documents[1]?.url ? 'pointer-events-none hover:text-darkGrey' : ''} color={!profileData.client.documents[1]?.url ? COLORS.darkGrey : COLORS.blue} />} disabled={!profileData.client.documents[1]?.url} label='View' href={profileData.client.documents[1]?.url} target="_blank" />
            </div>
            <div className='flex flex-col w-full gap-y-1'>
              <p className='text-14 font-medium'>SLH</p>
              <FormInput
                inputClassName='text-center text-base'
                className='w-full'
                value={profileData.partner.name}
                readOnly
              />
            </div>
            <div className='flex flex-col w-full items-start gap-y-1'>
              <p className='text-14 font-medium'>Gender</p>
              <Radio className='px-4 py-2.5 !bg-white border border-color-divider rounded-xl ' checked>{GENDER[profileData.profile.gender]}</Radio>
            </div>
            <FormInput
              className='w-full'
              label="Identify self as"
              value={profileData.profile.identifyas}
              readOnly
            />
          </div>
        </div>
      </div>
    </div>
  );
};

