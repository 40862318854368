import { Outlet } from 'react-router-dom';
import {SideMenu} from 'components';

const RootLayout = () => {
  return (
    <div className="flex items-start min-h-screen p-10 pl-7 bg-bgColor gap-x-6">
      <SideMenu/>
      <div className="flex h-full w-[calc(100vw-398px)]">
        <Outlet/>
      </div>
    </div>
  );
}

export default RootLayout;
