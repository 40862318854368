import React, { useEffect, useState } from 'react';
import { FormInput, IconPlus, IconRemove, OrangeButton, SmallButton } from 'components';
import { addAdditionalPaymentsByManager, getRequestData, removeAdditionalPaymentsByManager } from 'api/api';
import { endpoints } from 'api/endpoints';
import { ROLE_TYPES } from 'api/constants';

const DynamicRows = ({ invoice, onCancel }) => {
  const [invoiceFullData, setInvoiceFullData] = useState(null);
  const [rows, setRows] = useState([{ description: '', price: '' }]);

  const getInvoiceFullData = async (invoiceID) => {
    const { data } = await getRequestData(endpoints.getInvoice(invoiceID), ROLE_TYPES.slh_manager);
    setInvoiceFullData(data);
  };

  useEffect(() => {
    getInvoiceFullData(invoice.invoice_id);
  }, [invoice.invoice_id]);

  const addRow = () => {
    setRows([...rows, { description: '', price: '' }]);
  };

  const removeRow = (index) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  const removePayment = (invoiceID, paymentID) => {
    removeAdditionalPaymentsByManager(invoiceID, paymentID, (data) => {
      getInvoiceFullData(invoiceID);
    })
  }

  const handleInputChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSave = () => {
    const formData = {
      'details': rows,
    }

    addAdditionalPaymentsByManager(invoice.invoice_id, formData, () => onCancel());
  }

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col min-h-96'>
        {invoiceFullData?.extra_payments?.map((payment, index) => (
          <div className={`flex items-end pt-4 pb-6 ${index > 0 ? 'border-t border-divider ' : ''}`} key={index}>
            <FormInput
              className='flex-1'
              label="Description"
              placeholder="Description"
              value={payment.description}
            />
            <FormInput
              className='mr-4 ml-6 w-[150px]'
              label="Price"
              placeholder="Price"
              type='number'
              prefix='$'
              value={payment.price}
            />
            <IconRemove
              className='mb-2.5 cursor-pointer'
              onClick={() => removePayment(payment.invoice_id, payment.id)}
            />
          </div>
        ))}
        {rows.map((row, index) => (
          <div className={`flex items-end pt-4 pb-6 ${index > 0 ? 'border-t border-divider ' : ''}`} key={index}>
            <FormInput
              className='flex-1'
              label="Description"
              placeholder="Description"
              value={row.description}
              onChange={(e) => handleInputChange(index, 'description', e.target.value)}
            />
            <FormInput
              className='mr-4 ml-6 w-[150px]'
              label="Price"
              placeholder="Price"
              type='number'
              prefix='$'
              value={row.price}
              onChange={(e) => handleInputChange(index, 'price', e.target.value)}
            />
            <IconRemove
              className={`mb-2.5 ${rows.length === 1 ? 'pointer-events-none' : 'cursor-pointer'}`}
              onClick={() => removeRow(index)}
            />
          </div>
        ))}
        <div className='w-full flex justify-center mb-10 relative before:content-[""] before:w-full before:h-[1px] before:absolute before:top-1/2 before:left-0 before:right-0 before:bg-divider z-0'>
          <span onClick={addRow} className='rounded-full bg-light border-1 border-color-divider self-center p-3 cursor-pointer z-10'>
            <IconPlus />
          </span>
        </div>
      </div>
      <div className='flex justify-end w-full border-t border-divider gap-x-6 pt-[30px]'>
        <SmallButton
          btnClassName='!h-11'
          onClick={onCancel}
          label='Cancel' />
        <OrangeButton
          onClick={handleSave}
          label='Save'
          disabled={!rows[0].description || !rows[0].price}
        />
      </div>
    </div>
  );
};

export default DynamicRows;
