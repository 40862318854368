import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconFormPlus({ onClick, className, color = '#00AC4F' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-4 h-4', className)} fill="none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1294_8218)">
        <path d="M10.667 2.66683H12.0003C12.3539 2.66683 12.6931 2.80731 12.9431 3.05735C13.1932 3.3074 13.3337 3.64654 13.3337 4.00016V13.3335C13.3337 13.6871 13.1932 14.0263 12.9431 14.2763C12.6931 14.5264 12.3539 14.6668 12.0003 14.6668H4.00033C3.6467 14.6668 3.30756 14.5264 3.05752 14.2763C2.80747 14.0263 2.66699 13.6871 2.66699 13.3335V4.00016C2.66699 3.64654 2.80747 3.3074 3.05752 3.05735C3.30756 2.80731 3.6467 2.66683 4.00033 2.66683H5.33366M6.00033 9.3335H10.0003M8.00033 11.3335V7.3335M6.00033 1.3335H10.0003C10.3685 1.3335 10.667 1.63197 10.667 2.00016V3.3335C10.667 3.70169 10.3685 4.00016 10.0003 4.00016H6.00033C5.63214 4.00016 5.33366 3.70169 5.33366 3.3335V2.00016C5.33366 1.63197 5.63214 1.3335 6.00033 1.3335Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1294_8218">
          <rect height="16" width="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
