import React, { Fragment, useMemo } from 'react';
import { IconSmallArrow, IconStar } from 'components';
import { useNavigate } from 'react-router-dom';
import { Form, Radio, Input, Rate } from 'antd';

export const WeeklyRegularForm = ({ clientTasks }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const formData = clientTasks[0].forms[0];

  const storeFields = useMemo(() => {
    return formData.feedback.map((form, index) => {
      return (
        <Fragment key={index}>
          <div className='flex flex-col px-6 py-4 bg-white rounded-block-radius-sm gap-y-4'>
            <div className='flex items-center gap-x-2'>
              <b className='nunito text-orange italic'>{index + 1}.</b>
              <p className='font-medium text-16'>{form.question}</p>
            </div>
            <Form.Item
              name={form.feedback_id}
              rules={[{ required: form.mandatory, message: 'This field is required' }]}
            >
              {form.type === 'rating' ?
                  <Rate
                    disabled={true}
                    name={`input-${form.feedback_id}`}
                    character={({ index = 0 }) => {
                      return (
                        <div className='rate flex flex-col items-center rounded-xl border border-color-divider py-2.5 bg-white w-[78px] h-[78px] gap-y-2.5'>
                          <IconStar />
                          <span className='text-16 text-black rate-text'>{index + 1}</span>
                        </div>
                      )
                    }}
                  /> : form.type === 'radio' ?
                  (
                    <Radio.Group name={`input-${form.feedback_id}`}>
                      {form.items.map((item) => {
                        return (
                          <Radio
                            disabled={true}
                            key={`radio-${item.feedback_item_id}`}
                            className='px-4 py-2.5 bg-white border border-color-divider rounded-xl mr-0'
                            value={item.feedback_item_id}>
                            {item.feedback_item_label}
                          </Radio>
                        )
                      })}
                    </Radio.Group>
                  )
                : (
                  <Input.TextArea
                    readOnly={true}
                    className='!resize-none font-poppins text-14 p-3 pl-4 border border-color-divider rounded-ten max-h-[100px]'
                    autoSize={false}
                    name={`input-${form.feedback_id}`}
                    rows={4}
                    placeholder="Placeholder"
                  />
                )
              }
            </Form.Item>
          </div>
        </Fragment>
      )
    })
  }, [formData.feedback])

  const initialValues = useMemo(() => {
    const feedbackObject = formData.feedback.reduce((acc, item) => {
      if (item.items.length > 1) {
        const selectedItem = item.items.find(feedbackItem => feedbackItem.answer === String(feedbackItem.feedback_item_id) || feedbackItem.answer === String(feedbackItem.feedback_item_label));
        acc[item.feedback_id] = selectedItem && item.type === 'rating' ? selectedItem.answer : selectedItem?.feedback_item_id;
      } else {
        acc[item.feedback_id] = item.items[0].answer;
      }

      return acc;
    }, {});

    return feedbackObject;
  }, [formData.feedback]);

  return (
    <div className='flex flex-col flex-1 gap-y-8'>
      <div className='flex justify-between'>
        <div className='flex items-center gap-x-4'>
          <div onClick={() => navigate(-1)} className='min-w-10 h-10 bg-white rounded-lg shadow-block-shadow flex items-center justify-center cursor-pointer'>
            <IconSmallArrow className='w-2 h-4' />
          </div>
          <p className='nunito-title'>{formData.name}</p>
        </div>
      </div>
      <Form
        form={form}
        initialValues={initialValues}
        className='flex flex-col gap-y-4 client-forms'>
        {storeFields}
      </Form>
    </div>
  );
};