import React from 'react'
import { twMerge } from 'tailwind-merge';

export function IconTwoUser({ onClick, className, color = '#4318FF' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-5 h-5 hover:[&_path]:stroke-orange', className)} fill="none" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.49362 11.3705C9.39362 11.3605 9.27362 11.3605 9.16362 11.3705C6.78362 11.2905 4.89362 9.34049 4.89362 6.94049C4.89362 4.49049 6.87362 2.50049 9.33362 2.50049C11.7836 2.50049 13.7736 4.49049 13.7736 6.94049C13.7636 9.34049 11.8736 11.2905 9.49362 11.3705Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M16.7432 4.49951C18.6832 4.49951 20.2432 6.06951 20.2432 7.99951C20.2432 9.88951 18.7432 11.4295 16.8732 11.4995C16.7932 11.4895 16.7032 11.4895 16.6132 11.4995" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M4.49377 15.0595C2.07377 16.6795 2.07377 19.3195 4.49377 20.9295C7.24377 22.7695 11.7538 22.7695 14.5038 20.9295C16.9238 19.3095 16.9238 16.6695 14.5038 15.0595C11.7638 13.2295 7.25377 13.2295 4.49377 15.0595Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path d="M18.6732 20.5005C19.3932 20.3505 20.0732 20.0605 20.6332 19.6305C22.1932 18.4605 22.1932 16.5305 20.6332 15.3605C20.0832 14.9405 19.4132 14.6605 18.7032 14.5005" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  )
}