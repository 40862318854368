import React, { useEffect, useRef, useState } from 'react';
import { getRequestData } from 'api/api';
import { endpoints } from 'api/endpoints';
import { FinancesSinglePartner } from '../customTable/financesSinglePartner';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/scrollbar';
import { Button } from 'antd';
import { IconSmallArrow } from 'components';

export const FinancesPartners = ({ domain }) => {
  const [partners, setPartners] = useState([]);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  useEffect(() => {
    (async () => {
      const { data } = await getRequestData(endpoints.getFinancesPartners, domain);
      if (data) {
        setPartners(data);
      }
    })();
  }, [domain]);

  return partners.length > 0 && (
    <div className='flex w-full mb-6 items-center relative'>
      <div className='flex gap-x-4 overflow-x-auto w-full pt-6 pb-2 custom-scrollbar scrollbar scrollbar-thumb-sky-700 scrollbar-track-sky-300'>
        {partners.map((partner, index) => (
          <FinancesSinglePartner {...partner} key={`${partner.name}-${index}`} />
        ))}
      </div>
    </div>
  );
};
