import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconMark({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-7 h-5 group', className)} fill="none" viewBox="0 0 29 21" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.1664 15.4622L5.99978 8.82069C5.84724 8.66629 5.66557 8.54371 5.4653 8.46004C5.26503 8.37637 5.05015 8.33329 4.83311 8.33329C4.61607 8.33329 4.40118 8.37637 4.20092 8.46004C4.00065 8.54371 0.98564 11.0122 0.833102 11.1666C0.678703 11.3192 0.556117 11.5008 0.472451 11.7011C0.388785 11.9014 0.345703 12.1163 0.345703 12.3333C0.345703 12.5503 0.388785 12.7652 0.472451 12.9655C0.556117 13.1658 0.678703 13.3474 0.833102 13.5L7.81644 20.4833C8.46644 21.1333 9.51644 21.1333 10.1664 20.4833L27.8331 2.8333C27.9875 2.68077 28.1101 2.4991 28.1938 2.29883C28.2774 2.09856 28.3205 1.88368 28.3205 1.66664C28.3205 1.4496 28.2774 1.23471 28.1938 1.03445C28.1101 0.834179 27.9875 0.65251 27.8331 0.499972C27.6806 0.345573 27.4989 0.222986 27.2986 0.139321C27.0984 0.0556546 26.8835 0.0125732 26.6664 0.0125732C26.4494 0.0125732 26.2345 0.0556546 26.0342 0.139321C25.834 0.222986 25.6523 0.345573 25.4998 0.499972L10.1664 15.4622Z" fill={color} />
    </svg>
  )
}
