import React from 'react'
import { twMerge } from 'tailwind-merge'

export function IconPatient({ onClick, className, color = '#000' }) {
  return (
    <svg onClick={onClick} className={twMerge(' w-6 h-6 group', className)} fill="none" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path className='group-hover:stroke-orange' d="M11.8148 10.8702C11.7148 10.8602 11.5948 10.8602 11.4848 10.8702C9.10484 10.7902 7.21484 8.84024 7.21484 6.44024C7.21484 3.99024 9.19484 2.00024 11.6548 2.00024C14.1048 2.00024 16.0948 3.99024 16.0948 6.44024C16.0848 8.84024 14.1948 10.7902 11.8148 10.8702Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
      <path className='group-hover:stroke-orange' d="M6.815 14.5598C4.395 16.1798 4.395 18.8198 6.815 20.4298C9.565 22.2698 14.075 22.2698 16.825 20.4298C19.245 18.8098 19.245 16.1698 16.825 14.5598C14.085 12.7298 9.575 12.7298 6.815 14.5598Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
    </svg>
  )
}
