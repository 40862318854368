import React from 'react';
import { Navigate } from "react-router-dom";
import { useAuth } from 'context/authContext';
import { ROLE_TYPES } from 'api/constants';

export const IsAuthenticated = () => {
  const { auth, role } = useAuth();
  
  if (auth && role) {
    const path =
      role === ROLE_TYPES.admin ? "/admin/dashboard" :
      role === ROLE_TYPES.slh_manager ? "/manager/dashboard" :
      "/client/dashboard";

    return <Navigate to={path} />
  }

  return <Navigate to='/' />
};

