import React from 'react'
import { twMerge } from 'tailwind-merge';

export function IconApproximately({ onClick, className, color = '#000' }) {
  return (
    <svg  onClick={onClick} className={twMerge(' w-5 h-1.5', className)} fill="none" viewBox="0 0 16 9" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3929 2.04545C14.5357 2.96591 12.9286 3.88636 11.2143 3.88636C9.60714 3.88636 8.64286 3.375 7.78571 2.96591C6.92857 2.65909 6.07143 2.14773 4.67857 2.14773C3.39286 2.14773 2.21429 2.86364 1.57143 3.47727L0.5 1.94318C1.46429 1.02273 2.96429 0 4.67857 0C6.28571 0 7.25 0.613637 8.10714 0.920455C8.96429 1.22727 9.82143 1.84091 11.2143 1.84091C12.5 1.84091 13.6786 1.02273 14.3214 0.409091L15.3929 2.04545ZM15.5 7.05682C14.5357 7.97727 13.0357 9 11.3214 9C9.71429 9 8.75 8.48864 7.89286 8.07955C7.03571 7.77273 6.17857 7.15909 4.78571 7.15909C3.5 7.15909 2.32143 7.97727 1.67857 8.59091L0.607143 6.95455C1.57143 6.03409 3.07143 5.01136 4.78571 5.01136C6.39286 5.01136 7.35714 5.52273 8.21429 5.93182C9.07143 6.23864 9.92857 6.75 11.3214 6.75C12.6071 6.75 13.7857 5.93182 14.4286 5.31818L15.5 7.05682Z" fill={color} />
    </svg>
  )
}
