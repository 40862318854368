export const COLORS = {
  "white": '#fff',
  "black": "#000",
  "orange": "#FDB513",
  "yellow": "#FFF3D7",
  "lightBlue": "#A3AED0",
  "blue": "#4318FF",
  "grey": "#DCDCE4",
  "darkGrey": "#A7AAAF",
  "lightGrey": "#F3F3F3",
  'divider': "#E0E6FF",
  "light": "#EDF2FF",
  "bgColor": "#F5F7FF",
  "green": "#00AC4F",
  "lightGreen": "#D3FFE7",
  "red": "#D02B20",
  "pink": "#FFD4E4"
}
