import { Select } from 'antd';

export const SelectOption = ({ options, ...restProps }) => {

  return (
    <Select
      options={options}
      {...restProps}
    />
  )
}